import {
	type FormEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { applyPagination } from "../../utils/applyPagination";
import Sidepane from "../components/Sidepane";
import {
	createPlayer,
	deletePlayerById,
	listPlayers,
	updatePlayer,
} from "../../utils/firebase";
import PlayerDetails from "../components/PlayerDetails";
import PlayerTable from "../components/PlayerTable";
import type { IPlayer } from "../../entities/player.entity";
import { useSidePane } from "../hook/useSidePane";
import { CrudContainer } from "../components/CrudContainer";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

const usePlayers = (page: number, rowsPerPage: number, data: unknown) => {
	return useMemo(() => {
		return applyPagination(data, page, rowsPerPage);
	}, [page, rowsPerPage, data]);
};

export default function Players() {
	const [playerDetail, setPlayerDetails] = useState<IPlayer | undefined>(
		undefined,
	);
	const [data, setData] = useState<IPlayer[]>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const sidepane = useSidePane({
		onClose: () => setPlayerDetails(undefined),
	});

	const [isSaving, setIsSaving] = useState(false);
	const players = usePlayers(page, rowsPerPage, data);

	async function fetchData() {
		try {
			const data = await listPlayers();
			setData(data);
		} catch (error) {
			console.error(error);
			captureException(error);
		}
	}

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		fetchData();
	}, []);

	const handlePageChange = useCallback((_, value: number) => {
		setPage(value);
	}, []);

	const handleRowsPerPageChange = useCallback((event) => {
		setRowsPerPage(event.target.value);
	}, []);

	async function handleRemovePlayer(player: IPlayer) {
		if (!confirm("Deseja realmente remover este player?")) {
			return;
		}

		try {
			await deletePlayerById(player.id);
			fetchData();

			enqueueSnackbar({
				variant: "success",
				message: "Player removido com sucesso",
			});
		} catch (error) {
			console.error(error);
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao remover player, tente novamente",
			});
		}
	}

	function handleUpdatePlayer(id: string) {
		if (data.length > 0) {
			for (const player of data) {
				if (player.id === id) {
					setPlayerDetails(player);
					sidepane.setOpen(true);
				}
			}
		}
	}

	const handleSubmitPlayer = async (
		event: FormEvent<HTMLFormElement>,
		data: IPlayer,
	) => {
		event.preventDefault();

		setIsSaving(true);

		try {
			if (data.id) {
				await updatePlayer(data);
				await fetchData();
			} else {
				await createPlayer(data);
				await fetchData();
			}

			enqueueSnackbar({
				variant: "success",
				message: "Player salvo com sucesso",
			});

			sidepane.setOpen(false);
		} catch (error) {
			console.error(error);
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao salvar player, tente novamente",
			});
		}
		setIsSaving(false);
	};

	return (
		<>
			<CrudContainer
				title="Players"
				onClickAdd={() => sidepane.setOpen(true)}
				subtitle="Gerencie os players cadastrados"
				empty={data.length === 0}
			>
				{data.length > 0 && (
					<PlayerTable
						count={data.length}
						items={players}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						onRemoveItem={handleRemovePlayer}
						onUpdateItem={handleUpdatePlayer}
						page={page}
						rowsPerPage={rowsPerPage}
					/>
				)}
			</CrudContainer>

			<Sidepane open={sidepane.isOpen} toggle={sidepane.toggle}>
				<PlayerDetails
					isSaving={isSaving}
					item={playerDetail}
					handleSubmitPlayer={handleSubmitPlayer}
				/>
			</Sidepane>
		</>
	);
}
