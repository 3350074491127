import { OutlinedInput, InputAdornment, SvgIcon } from "@mui/material";
import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";

export function SearchInput(props: {
	onChange: (value: string) => void;
	value: string | undefined;
}) {
	return (
		<OutlinedInput
			sx={{
				backgroundColor: "#00397e99",
				color: "white",
				borderRadius: "50vw",
			}}
			className="text-[200%] py-[0.5vw] px-[2vw]"
			fullWidth
			value={props.value}
			placeholder="Buscar"
			onChange={(e) => {
				console.log(e.target.value);
				props.onChange(e.target.value);
			}}
			type="search"
			startAdornment={
				<InputAdornment sx={{ mt: -0.5, mr: 1.5 }} position="start">
					<SvgIcon sx={{ color: "white" }}>
						<MagnifyingGlassIcon />
					</SvgIcon>
				</InputAdornment>
			}
		/>
	);
}
