/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-refresh/only-export-components */
import { type FormEvent, useCallback, useMemo, useState } from "react";
import CategorieTable from "../components/CategorieTable";
import { applyPagination } from "../../utils/applyPagination";
import Sidepane from "../components/Sidepane";
import CategorieDetails from "../../dashboard/components/CategorieDetail";
import {
	firebaseAddFirestore,
	firebaseAddStorage,
	firebaseUpdateFirestore,
} from "../../utils/firebase";
import type { ICategory } from "../../entities/categorie.entity";
import { useSidePane } from "../hook/useSidePane";
import { useCategoriesList } from "../hook/useCategoriesList";
import { useCategoryRemover } from "../hook/useCategoryRemover";
import { CrudContainer } from "../components/CrudContainer";

const useCategoriesPaginated = (
	page: number,
	rowsPerPage: number,
	data: unknown,
) => {
	return useMemo(() => {
		return applyPagination(data, page, rowsPerPage);
	}, [page, rowsPerPage, data]);
};

export default function Categories() {
	const [categorieDetail, setCategorieDetail] = useState<ICategory | undefined>(
		undefined,
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const categoriesQuery = useCategoriesList();
	const categories = useCategoriesPaginated(
		page,
		rowsPerPage,
		categoriesQuery.data || [],
	);
	const sidepane = useSidePane({
		onClose: () => setCategorieDetail(undefined),
	});

	const categoryRemover = useCategoryRemover();

	const handlePageChange = useCallback<
		NonNullable<Parameters<typeof CategorieTable>[0]["onPageChange"]>
	>((_, value: number) => {
		setPage(value);
	}, []);

	const handleRowsPerPageChange = useCallback<
		NonNullable<Parameters<typeof CategorieTable>[0]["onRowsPerPageChange"]>
	>((event) => {
		setRowsPerPage(event.target.value);
	}, []);

	function handleUpdateCategorie(id: string) {
		if (!categoriesQuery.data) return;

		for (const categorie of categoriesQuery.data) {
			if (categorie.id === id) {
				setCategorieDetail(categorie);

				sidepane.setOpen(true);
			}
		}
	}

	const handleCategorySaved = async () => {
		sidepane.setOpen(false);
	};

	return (
		<>
			<CrudContainer
				title="Categorias"
				onClickAdd={() => sidepane.setOpen(true)}
				empty={categoriesQuery.data?.length === 0}
			>
				{categories.length > 0 && (
					<CategorieTable
						count={categoriesQuery.data?.length || 0}
						items={categories}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						categoryRemover={categoryRemover}
						onUpdateCategorie={handleUpdateCategorie}
						page={page}
						rowsPerPage={rowsPerPage}
					/>
				)}
			</CrudContainer>

			<Sidepane open={sidepane.isOpen} toggle={sidepane.setOpen}>
				<CategorieDetails
					item={categorieDetail}
					onCategorySaved={handleCategorySaved}
				/>
			</Sidepane>
		</>
	);
}
