import type { IAnalytics, IMedia } from "./IAnalytics";
import axios, { type Axios } from "axios";

export class MegaMidiaTiagoAnalytics implements IAnalytics {
	request: Axios;

	constructor() {
		this.request = axios.create({
			baseURL: "https://api.tvtreinamento.com.br/player.php",
		});
	}

	async video(params: {
		playerId: string;
		videoId: string;
		videoName: string;
	}): Promise<boolean> {
		await this.request.post("", {
			action: "playing",
			player: {
				id: params.playerId,
			},
			id_media: params.videoId,
			nome_media: params.videoName,
			timestamp: new Date().toISOString(),
		});
		return true;
	}

	async click(playerId: string, button: string) {
		await this.request.post("", {
			action: "click",
			player: {
				id: playerId,
			},
			button,
			timestamp: new Date().toISOString(),
		});
		return true;
	}

	async ping(params: {
		playerId: string;
		uptime: number;
		state: "tocando" | "screensaver" | "parado";
		medias_total: number;
		medias: IMedia[];
		media?: IMedia;
	}) {
		await this.request.post("", {
			action: "ping",
			player: {
				id: params.playerId,
			},
			uptime: params.uptime,
			medias_total: params.medias_total,
			state: params.state,
			medias: params.medias,
		});

		return true;
	}
}
