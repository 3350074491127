import { playerActivationStore } from "../../utils/firebase";
import { useVideoPlayerStore } from "../../utils/store";
import { useAnalytics } from "../hooks/useAnalytics";
import tvMuralSvg from "../../assets/buttons/tv_zonta_tvmural__text.svg";

export function TvMuralButton() {
	const videoPlayer = useVideoPlayerStore();
	const analytics = useAnalytics();
	return (
		<button
			onClick={() => {
				videoPlayer.playSplash();
				analytics.click(playerActivationStore.activationPassword!, "TV Mural");
			}}
			className="uppercase text-white text-4xl space-x-2 mt-[1vh] py-[2vw] px-[2vw] rounded-full bg-black bg-opacity-0 hover:bg-opacity-30 transition-all mb-8 md:mb-0"
			data-name="TV Mural"
		>
			<img src={tvMuralSvg} alt="TV Mural" className="w-[30vw]" />
		</button>
	);
}
