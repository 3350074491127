import {
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import type { IPlayer } from "../../entities/player.entity";

interface playerTableProps {
	count: number;
	items: IPlayer[];
	onPageChange: (event: any, value: number) => void;
	onRowsPerPageChange: (event: any) => void;
	onRemoveItem: (video: IPlayer) => void;
	onUpdateItem: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function PlayerTable(props: playerTableProps) {
	const {
		count = 0,
		items = [],
		onPageChange = () => {},
		onRowsPerPageChange,
		onRemoveItem,
		onUpdateItem,
		page = 0,
		rowsPerPage = 0,
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>Senha</TableCell>
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => {
						return (
							<TableRow hover key={item.id}>
								<TableCell>{item.name}</TableCell>
								<TableCell>{item.password}</TableCell>
								<TableCell size="small">
									<Stack
										sx={{
											display: "inline-flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<IconButton
											aria-label="edit"
											onClick={() => onUpdateItem(item.id)}
											color="primary"
										>
											<SvgIcon>
												<PencilIcon />
											</SvgIcon>
										</IconButton>
										<IconButton
											aria-label="trash"
											color="error"
											onClick={() => onRemoveItem(item)}
										>
											<SvgIcon>
												<TrashIcon />
											</SvgIcon>
										</IconButton>
									</Stack>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
