import React from "react";
import type { IAnalytics } from "../analytics/IAnalytics";

const AnalyticsContext = React.createContext<IAnalytics | null>(null);

export const AnalyticsProvider = ({
	children,
	analytics,
}: { children: React.ReactNode; analytics: IAnalytics }) => {
	return (
		<AnalyticsContext.Provider value={analytics}>
			{children}
		</AnalyticsContext.Provider>
	);
};

export const useAnalytics = () => {
	const analytics = React.useContext(AnalyticsContext);
	if (!analytics) {
		throw new Error("useAnalytics must be used within a AnalyticsProvider");
	}
	return analytics;
};
