import type { IUser } from "../../entities/user.entity";
import { deleteUserById } from "../../utils/firebase";
import { useMutation } from "@tanstack/react-query";
import { useUsersList } from "./useUsersList";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

export const useUserRemover = () => {
	const usersList = useUsersList();
	return useMutation({
		mutationKey: ["users", "delete"],
		mutationFn: async (user: IUser) => {
			await deleteUserById(user.id);
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao deletar usuário, tente novamente",
			});
		},
		onSuccess: () => {
			usersList.refetch();
			enqueueSnackbar({
				variant: "success",
				message: "Usuário deletado com sucesso",
			});
		},
	});
};
