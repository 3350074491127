import bg from "../assets/tv_zonta_bg.png";

export function CenterBigButtons({ children }: { children: React.ReactNode }) {
	return (
		<div className="flex w-full flex-col justify-center items-center md:h-full">
			{children}
		</div>
	);
}

export function PageWithBackground({
	children,
}: { children: React.ReactNode }) {
	return (
		<div
			className="bg-cover h-screen w-screen bg-center overflow-y-scroll md:overflow-y-auto md:overflow-x-hidden"
			style={{
				backgroundImage: `url(${bg})`,
			}}
		>
			{children}
		</div>
	);
}

export function PageContainter({
	children,
	disabled = false,
}: { children: React.ReactNode; disabled?: boolean }) {
	if (disabled) {
		return children;
	}

	return (
		<div className="flex-grow md:overflow-y-auto md:overflow-x-hidden text-white px-[5%]">
			<div className="mx-auto w-full h-full">{children}</div>
		</div>
	);
}
