import { listCategories } from "../utils/firebase";
import type { ICategory } from "../entities/categorie.entity";

const cache = new Map<string, ICategory[]>();

export const lazyCategories = async (disableCache = false) => {
	const cacheEnabled = !disableCache;
	let categories: ICategory[] = [];

	const findSubCategoryById = (id: string) => {
		for (const category of categories || []) {
			for (const subCategory of category.subCategories || []) {
				if (subCategory.id === id) return subCategory;
			}
		}
	};

	const findCategoryById = (id: string) => {
		for (const category of categories || []) {
			if (category.id === id) return category;
		}
	};

	if (cacheEnabled) {
		const cachedCategories = cache.get("categories");
		if (cachedCategories) categories = cachedCategories;
	}

	if (!categories.length) {
		const categoriesResult = await listCategories();
		if (categoriesResult) {
			cache.set("categories", categoriesResult);
		}
		categories = categoriesResult;
	}

	console.log("categories", categories);

	return {
		categories,
		findSubCategoryById,
		findCategoryById,
	};
};
