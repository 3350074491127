import { INACTIVE_TIMEOUT } from "./constants";

class InactiveTimerMeasurer {
	timer?: ReturnType<typeof setTimeout>;
	onInactive?: () => void;
	onActive?: () => void;

	constructor() {
		this.reset();
	}

	setOnInactive(onInactive: () => void) {
		this.onInactive = onInactive;
	}

	setOnActive(onActive: () => void) {
		this.onActive = onActive;
	}

	active() {
		this.onActive?.();
		this.reset();
	}

	inactive() {
		this.onInactive?.();
		this.reset();
	}

	reset() {
		if (this.timer) {
			clearTimeout(this.timer);
		}

		this.timer = setTimeout(() => {
			this.onInactive?.();
		}, INACTIVE_TIMEOUT);
	}
}

export const inactiveTimerMeasurer = new InactiveTimerMeasurer();
