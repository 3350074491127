import MagnifyingGlassIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { InputAdornment, OutlinedInput, SvgIcon } from "@mui/material";
import type { KeyboardEvent } from "react";

interface TableSearchProps {
	handleChangeSearch: (
		e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
	) => void;
}

export default function TableSearch(props: TableSearchProps) {
	return (
		<OutlinedInput
			defaultValue=""
			fullWidth
			placeholder="Buscar"
			onKeyDown={props.handleChangeSearch}
			startAdornment={
				<InputAdornment position="start">
					<SvgIcon color="action" fontSize="small">
						<MagnifyingGlassIcon />
					</SvgIcon>
				</InputAdornment>
			}
			sx={{ maxWidth: 350 }}
		/>
	);
}
