export const openUrl = (url: string) => {
	const isInternal = url.includes(window.location.origin);

	if (isInternal) {
		window.location.href = url;
		return;
	}

	if (window.api) {
		// Send the url to the main process (Electron)
		window.api.openExternalLink(url);
		return;
	}

	window.open(url, "_blank");
};
