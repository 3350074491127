import {
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import DeleteButtonIcon from "./DeleteButtonIcon";
import type { useUserRemover } from "../hook/useUserRemover";
import type { IUser } from "../../entities/user.entity";

interface UserTableProps {
	count: number;
	items: IUser[];
	onPageChange: (event, value: number) => void;
	onRowsPerPageChange: (event) => void;
	userRemover: ReturnType<typeof useUserRemover>;
	onUpdateUser: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function UserTable(props: UserTableProps) {
	const {
		count = 0,
		items = [] as IUser[],
		onPageChange = () => {},
		onRowsPerPageChange,
		userRemover,
		onUpdateUser,
		page = 0,
		rowsPerPage = 0,
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>E-mail</TableCell>
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((user) => {
						return (
							<TableRow hover key={user.id}>
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">{user.id}</Typography>
									</Stack>
								</TableCell>
								<TableCell size="small">
									<Stack
										sx={{
											display: "inline-flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<IconButton
											aria-label="edit"
											color="primary"
											onClick={() => onUpdateUser(user.id)}
										>
											<SvgIcon>
												<PencilIcon />
											</SvgIcon>
										</IconButton>
										<DeleteButtonIcon
											onClick={() => userRemover.mutate(user)}
											loading={
												userRemover.isLoading &&
												userRemover.variables?.id === user.id
											}
											disabled={userRemover.isLoading}
										/>
									</Stack>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
