import { initializeApp } from "firebase/app";

import {
	getDocs,
	deleteDoc,
	updateDoc,
	collection,
	doc,
	where,
	query,
	initializeFirestore,
	setDoc,
	type DocumentReference,
	type QueryFieldFilterConstraint,
	connectFirestoreEmulator,
} from "firebase/firestore";
import {
	connectFunctionsEmulator,
	getFunctions,
	httpsCallable,
} from "firebase/functions";
import {
	getDownloadURL,
	getStorage,
	ref,
	uploadBytes,
	deleteObject,
	connectStorageEmulator,
} from "firebase/storage";

import { connectAuthEmulator, getAuth } from "firebase/auth";
import type { IVideo } from "../entities/video.entity";
import _ from "lodash";
import { lazyCategories } from "../hooks/useCategories";
import type { IGroup } from "../entities/group.entity";
import type { IPlayer } from "../entities/player.entity";
import type { IEmbed } from "../entities/embed.entity";
import type { ICategory } from "../entities/categorie.entity";
import type { IUser } from "../entities/user.entity";
import { captureException } from "@sentry/react";

export const firebaseApp = initializeApp({
	apiKey: "AIzaSyDBe5_ToY2FcN2uGF_w7GalTaQd52brNMQ",
	authDomain: window.location.origin.includes("localhost")
		? "localhost:5173"
		: window.location.host,
	projectId: "condorflix-dev",
	storageBucket: "condorflix-dev.appspot.com",
	messagingSenderId: "391592873342",
	appId: "1:391592873342:web:b611cfe14ce9daa794f72f",
	// measurementId: "G-2X5V2LQH6Y" // TODO: add analytics
});

const db = initializeFirestore(firebaseApp, {});
export const storage = getStorage(firebaseApp);
// export const analytics = getAnalytics(firebaseApp)
export const functions = getFunctions(firebaseApp);
export const auth = getAuth(firebaseApp);

// if (window.location.hostname.includes("localhost")) {
// 	// Ref: https://firebase.google.com/docs/emulator-suite/connect_auth?hl=pt#web-modular-api
// 	connectAuthEmulator(auth, "http://localhost:9099");
// 	connectFirestoreEmulator(db, "localhost", 8080);
// 	connectStorageEmulator(storage, "localhost", 9199);
// 	connectFunctionsEmulator(functions, "localhost", 5001);
// }

export async function firebaseAddFirestore<T extends object>(
	colectionName: string,
	data: Omit<T, "id" | "createdAt"> & { id?: string; createdAt?: Date },
) {
	let nextDoc: DocumentReference;
	if (!data.id) {
		console.log("firebaseAddFirestore", { colectionName, data });
		nextDoc = doc(collection(db, colectionName));
		data.id = nextDoc.id;
	} else {
		nextDoc = doc(db, colectionName, data.id);
	}
	console.log("firebaseAddFirestore", { colectionName, data });
	data.createdAt = new Date();
	await setDoc(nextDoc, data);
	console.log("firebaseAddFirestore done", { colectionName, data });
	return data as T & { id: string };
}

export async function firebaseReadFirestore<T>(colectionName: string) {
	const querySnapshot = await getDocs(collection(db, colectionName));

	return querySnapshot.docs.map((doc) => ({
		...doc.data(),
		id: doc.id,
	})) as T[];
}

export async function getPlayerByPassword(password: string) {
	const playersMatched = await getDocs(
		query(collection(db, "players"), where("password", "==", password)),
	);

	if (playersMatched.docs.length === 1) {
		return playersMatched.docs[0].data() as IPlayer;
	}

	throw new Error("Senha inválida");
}

export async function validateWithRemotePassword(password: string) {
	console.log("validateWithRemotePassword", { password });
	try {
		const player = await getPlayerByPassword(password);

		console.log("player", player);

		if (player) {
			return true;
		}
	} catch (e) {
		captureException(e);
		console.log("validateWithRemotePassword error", e);
		console.error(e);
	}

	return false;
}

export function validateWithLocalPassword(password: string) {
	return window.localStorage.getItem("activationPassword") === password;
}

export async function validatePassword(password: string) {
	console.log("will validate password", { password });

	if (await validateWithRemotePassword(password)) {
		console.log("remote password is valid", { password });
		return true;
	}

	console.log("remote password is invalid", { password });

	if (validateWithLocalPassword(password)) {
		console.log("local password is valid", { password });
		return true;
	}

	console.log("local password is invalid", { password });

	if (password === "p8ftUSW2b1") {
		console.log("recover password is valid", { password });
		return true;
	}

	console.log("recover password is invalid", { password });

	return false;
}

export const playerActivationStore = {
	get isActivated() {
		return localStorage.getItem("activated") === "true";
	},
	get activationPassword() {
		return localStorage.getItem("activationPassword");
	},
	setActive(password: string) {
		localStorage.setItem("activated", "true");
		localStorage.setItem("activationPassword", password);
	},
};

export async function activatePlayer(password: string) {
	const passwordIsValid = await validatePassword(password);
	if (!passwordIsValid) {
		console.log("password is invalid", { password });
		throw new Error("Senha inválida");
	}
	console.log("password is valid", { password });
	playerActivationStore.setActive(password);
	return true;
}

export async function fetchCategoryVideos(q: { categoryId?: string }) {
	const filters: QueryFieldFilterConstraint[] = [];

	if (q.categoryId) {
		filters.push(where("category.id", "==", q.categoryId));
	}

	const data = await getDocs(query(collection(db, "videos"), ...filters));
	const categories = await lazyCategories();

	return _.chain(data.docs)
		.map((doc) => doc.data())
		.map((video) => {
			const category = categories.findCategoryById(video.category.id);
			const subcategory = categories.findSubCategoryById(video.subcategory.id);
			return {
				...video,
				category,
				subcategory,
			};
		})
		.groupBy("subcategory.id")
		.values()
		.value() as unknown as IVideo[][];
}

export async function firebaseDeleteFirestore(
	docId: string,
	colection: string,
	filesIds: string[],
) {
	console.log("firebaseDeleteFirestore", { docId, colection, filesIds });

	if (filesIds) {
		for (const fileId of filesIds) {
			const fileRef = ref(storage, fileId);
			await deleteObject(fileRef).catch((error) => {
				console.error(error);
				captureException(error);
				// fail silently
			});
		}
	}

	await deleteDoc(doc(db, colection, docId));
}

export async function firebaseUpdateFirestore<T extends { id: string }>(
	colection: string,
	data: T,
) {
	await updateDoc(doc(db, `${colection}/${data.id}`), data);
}

export async function firebaseAddStorage(
	path: string,
	data: File,
): Promise<{ id: string; url: string }> {
	const storageRef = ref(
		storage,
		`${path}/${data.name.replace(".", `-${Date.now()}.`)}`,
	);

	const response = {
		id: "",
		url: "",
	};

	const uploadedObject = await uploadBytes(storageRef, data);
	response.id = uploadedObject.ref.fullPath;
	response.url = await getDownloadURL(storageRef);

	return response;
}

export interface S3PresignedUrlResult {
	signedUpload: S3SignedLoad;
	signedDownload: S3SignedLoad;
}

export interface S3SignedLoad {
	url: string;
	method: string;
	headers: Headers;
	fullPath: string;
}

export async function createPresignedUrl(
	file: File,
	kind: "video" | "thumbnail",
) {
	const createPresignedUrl = httpsCallable(functions, "createPresignedUrl");

	console.log("createPresignedUrl", { file, kind });

	const response = await createPresignedUrl({
		fileName: file.name,
		fileSize: file.size,
		filePath: kind,
	});

	console.log("createPresignedUrl response", response);

	return response.data as S3PresignedUrlResult;
}

export function updateEmbed(data: IEmbed) {
	return firebaseUpdateFirestore("embeds", data);
}

export function createEmbed(data: Omit<IEmbed, "id" | "createdAt">) {
	return firebaseAddFirestore("embeds", { ...data, createdAt: new Date() });
}

export function listEmbeds() {
	return firebaseReadFirestore<IEmbed>("embeds");
}

export function deleteEmbedById(id: string) {
	return firebaseDeleteFirestore(id, "embeds", []);
}

export function listGroups() {
	return firebaseReadFirestore<IGroup>("groups");
}

export function listUsers() {
	return firebaseReadFirestore<IUser>("users");
}

export function deleteGroupById(id: string) {
	return firebaseDeleteFirestore(id, "groups", []);
}

export function updateGroupById(data: IGroup) {
	return firebaseUpdateFirestore("groups", data);
}

export function createGroup(data: IGroup) {
	return firebaseAddFirestore<IGroup>("groups", {
		...data,
		createdAt: new Date(),
	});
}

export function updatePlayer(data: IPlayer) {
	return firebaseUpdateFirestore("players", data);
}

export function createPlayer(data: Omit<IPlayer, "id" | "createdAt">) {
	return firebaseAddFirestore("players", { ...data, createdAt: new Date() });
}

export function listPlayers() {
	return firebaseReadFirestore<IPlayer>("players");
}

export function deletePlayerById(id: string) {
	return firebaseDeleteFirestore(id, "players", []);
}

export function createVideo(data: Omit<IVideo, "id" | "createdAt">) {
	return firebaseAddFirestore("videos", { ...data, createdAt: new Date() });
}

export function updateVideoById(data: IVideo) {
	return firebaseUpdateFirestore("videos", data);
}

export async function queryVideos(groups: string[] = []) {
	// return firebaseReadFirestore<IVideo>('videos');
	const filters: QueryFieldFilterConstraint[] = [];

	if (groups.length > 0) {
		filters.push(where("groups", "array-contains-any", groups));
	}

	const docs = await getDocs(query(collection(db, "videos"), ...filters));
	return docs.docs.map((doc) => doc.data()) as IVideo[];
}

export function deleteVideoById(id: string) {
	return firebaseDeleteFirestore(id, "videos", []);
}

export async function createUserAccess(email: string) {
	try {
		const docRef = await setDoc(doc(collection(db, "users"), email), {
			allowed: true,
		});
		console.log("Document written with ID: ", docRef);
	} catch (e) {
		captureException(e);
		console.error("Error adding document: ", e);
	}
}

export function listCategories(): ICategory[] | PromiseLike<ICategory[]> {
	return firebaseReadFirestore<ICategory>("categories");
}

export function deleteCategoryById(id: string) {
	return firebaseDeleteFirestore(id, "categories", []);
}

export function updateCategoryById(data: ICategory) {
	for (const subCategory of data.subCategories) {
		if (!subCategory.id) {
			subCategory.id = doc(collection(db, "subCategories")).id;
		}
	}

	return firebaseUpdateFirestore("categories", data);
}

export function createCategory(data: Omit<ICategory, "id" | "createdAt">) {
	for (const subCategory of data.subCategories) {
		if (!subCategory.id) {
			subCategory.id = doc(collection(db, "subCategories")).id;
		}
	}

	return firebaseAddFirestore<ICategory>("categories", {
		...data,
		createdAt: new Date(),
	});
}

export function deleteUserById(id: string) {
	return firebaseDeleteFirestore(id, "users", []);
}

export function updateCategory(data: ICategory) {
	return firebaseUpdateFirestore("categories", data);
}
