import { Box, Button, SvgIcon, Typography } from "@mui/material";
import { DashboardCard } from "../pages/Home";
import { PageContainer } from "../pages/LoginPage";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { IconZzz } from "@tabler/icons-react";

type PossibleChildren = JSX.Element | string | null | false | undefined;

type Props = {
	title?: string;
	subtitle?: string;
	children?: PossibleChildren | PossibleChildren[];
	onClickAdd?: () => void;
	empty?: boolean;
};

export const CrudContainer = ({
	title,
	subtitle,
	children,
	onClickAdd,
	empty = false,
}: Props) => {
	return (
		<PageContainer title={title} subtitle={subtitle}>
			<DashboardCard
				title={title}
				subtitle={subtitle}
				action={
					<Button
						startIcon={
							<SvgIcon>
								<PlusIcon />
							</SvgIcon>
						}
						variant="contained"
						onClick={onClickAdd}
					>
						Criar
					</Button>
				}
			>
				<>
					{empty ? (
						<Box sx={{ height: "100%", p: 2 }}>
							<Box
								sx={{
									height: "100%",
									border: 4,
									borderColor: "grey.200",
									color: "grey.400",
									borderRadius: 8,
									p: 4,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									flexDirection: "column",
								}}
								style={{ borderStyle: "dashed" }}
							>
								<IconZzz size={48} />
								{title && (
									<Typography>
										Sem "{title.toLowerCase()}" cadastrados
									</Typography>
								)}
							</Box>
						</Box>
					) : (
						children
					)}
				</>
			</DashboardCard>
		</PageContainer>
	);
};
