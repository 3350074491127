import { listGroups } from "../utils/firebase";

export const lazyGroups = async () => {
	const groups = await listGroups();

	const findGroupById = (id: string) => {
		for (const group of groups || []) {
			if (group.id === id) return group;
		}
	};

	return {
		groups,
		findGroupById,
	};
};
