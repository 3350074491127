import { deleteCategoryById } from "../../utils/firebase";
import type { ICategory } from "../../entities/categorie.entity";
import { useMutation } from "@tanstack/react-query";
import { useCategoriesList } from "./useCategoriesList";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

export const useCategoryRemover = () => {
	const categoriesList = useCategoriesList();

	return useMutation({
		mutationKey: ["categories", "delete"],
		mutationFn: async (category: ICategory) => {
			await deleteCategoryById(category.id);
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				message: "Erro ao deletar categoria",
				variant: "error",
			});
		},
		onSuccess: () => {
			categoriesList.refetch();
			enqueueSnackbar({
				message: "Categoria deletada com sucesso",
				variant: "success",
			});
		},
	});
};
