import { useCurrentPlayer } from "./hooks/useVideosPreload";
import { useQuery } from "@tanstack/react-query";
import { playerActivationStore } from "../utils/firebase";
import { fetchAllContent } from "../utils/fetchAllContent";
import { LOOP_VIDEOS_REFETCH_INTERVAL } from "../utils/constants";
import { useVideoPlayerStore } from "../utils/store";
import { useEffect } from "react";

export const usePlayerData = () => {
	const currentPlayer = useCurrentPlayer();
	const videoPlayer = useVideoPlayerStore();
	const query = useQuery({
		queryKey: ["playerData", playerActivationStore.activationPassword],
		queryFn: () => {
			return fetchAllContent(playerActivationStore.activationPassword!);
		},
		enabled: Boolean(playerActivationStore.activationPassword),
		refetchInterval: LOOP_VIDEOS_REFETCH_INTERVAL,
	});

	useEffect(() => {
		if (query.data) {
			videoPlayer.setSplashs(
				query.data.splash?.map((video) => {
					return {
						originalUrl: video.video.url,
					};
				}),
			);
		}
	}, [query.data]);

	return {
		currentPlayer,
		query,
		password: playerActivationStore.activationPassword,
		getVideoByUrl: (url: string) => {
			return query.data?.videos.find((video) => video.video.url === url);
		},
	};
};
