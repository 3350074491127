import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useVideoPlayerStore } from "../../utils/store";
import { makeFullscreen } from "../../utils/makeFullscreen";
import { motion } from "framer-motion";
import { useKeyPressEvent, useMouse } from "react-use";
import { Header } from "./Header";

export function FullScreenPlayer() {
	const videoElRef = useRef<HTMLVideoElement>(null);
	const [isForcedFullscreen, setIsForcedFullscreen] = useState(false);
	const videoPlayer = useVideoPlayerStore();

	const currentVideo = videoPlayer.videos[0];
	const isSingleVideo = videoPlayer.videos.length === 1;

	const mousePos = useMouse({
		current: document.body,
	});

	useKeyPressEvent("Escape", () => {
		videoPlayer.stop();
	});

	const [currentTime, setCurrentTime] = useState(0);

	useLayoutEffect(() => {
		if (videoElRef.current) {
			if (videoPlayer.isSplash) {
				makeFullscreen(videoElRef.current, async () => {
					setIsForcedFullscreen(true);
				});
			}
		}
	}, [videoElRef.current, videoPlayer.isSplash]);

	// on video changes the source
	useEffect(() => {
		if (videoElRef.current) {
			videoElRef.current.src = currentVideo?.url || currentVideo?.originalUrl;
			videoElRef.current.currentTime = currentTime;
		}
	}, [currentVideo?.url, currentVideo?.originalUrl]);

	useEffect(() => {
		if (videoElRef.current) {
			videoElRef.current.currentTime = 0;
		}
	}, [currentVideo?.originalUrl]);

	const handleTimeUpdate = () => {
		setCurrentTime(videoElRef.current!.currentTime!);
	};

	if (!videoPlayer.isPlaying) {
		return <></>;
	}

	const videoRendered = (
		<>
			{videoPlayer.isSplash ? null : <Header />}
			<div
				className="w-full flex-grow"
				style={{
					flex: "0 1 100%",
					height: "50%",
					display: "flex",
					justifyContent: "flex-start",
				}}
			>
				<video
					className="h-full w-auto"
					src={currentVideo?.url || currentVideo?.originalUrl}
					autoPlay
					style={{
						flex: "0 1 100%",
					}}
					ref={videoElRef}
					muted={videoPlayer.isSplash}
					controls={!videoPlayer.isSplash}
					loop={videoPlayer.isLoop && isSingleVideo}
					onEnded={() => {
						videoPlayer.videoFinished();
					}}
					onTimeUpdate={handleTimeUpdate}
				/>
			</div>
		</>
	);

	if (videoPlayer.isSplash && !isForcedFullscreen) {
		return videoRendered;
	}

	if (!videoPlayer.isSplash) {
		return (
			<motion.div
				className="w-screen h-screen object-contain fixed z-10 bg-black flex flex-col"
				initial={{
					scale: 0.1,
					origin: "center",
					top: mousePos.docY,
					left: mousePos.docX,
					translateX: "-50%",
					translateY: "-50%",
				}}
				transition={{ duration: 0.6, ease: "easeOut" }}
				animate={{ scale: 1, top: 0, left: 0, translateX: 0, translateY: 0 }}
				exit={{ opacity: 0, scale: 0 }}
			>
				{videoRendered}
			</motion.div>
		);
	}

	return (
		<motion.div
			className="w-screen h-screen object-contain fixed z-10 bg-black flex flex-col"
			initial={{ opacity: 0 }}
			transition={{ duration: 0.6, ease: "easeOut" }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
		>
			{videoRendered}
		</motion.div>
	);
}
