import { useQuery } from "@tanstack/react-query";
import { lazyGroups } from "../../hooks/useGroups";

export function useGroupsList() {
	return useQuery({
		queryKey: ["groups", "list"],
		async queryFn() {
			const data = await lazyGroups();
			return data.groups;
		},
	});
}
