import { deleteGroupById } from "../../utils/firebase";
import { useMutation } from "@tanstack/react-query";
import type { IGroup } from "../../entities/group.entity";
import { useGroupsList } from "./useGroupsList";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

export const useGroupRemover = () => {
	const groupList = useGroupsList();
	return useMutation({
		mutationKey: ["groups", "delete"],
		mutationFn: async (group: IGroup) => {
			await deleteGroupById(group.id);
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({ variant: "error", message: "Erro ao deletar grupo" });
		},
		onSuccess: () => {
			enqueueSnackbar({
				variant: "success",
				message: "Grupo deletado com sucesso",
			});
			groupList.refetch();
		},
	});
};
