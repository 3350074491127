"use client";
import { Container, } from "@mui/material";
import {
	Avatar,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import type React from "react";
import { useState } from "react";
import {
	Box,
	AppBar,
	Toolbar,
	Stack,
	IconButton,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import {
	IconBinaryTreeFilled,
	IconCode,
	IconDeviceTv,
	IconHome,
	IconLabelFilled,
	IconLogout,
	IconMenu,
	IconMovie,
} from "@tabler/icons-react";
import { Drawer } from "@mui/material";
import { Sidebar } from "react-mui-sidebar";

import { ListItem, List, useTheme, ListItemButton } from "@mui/material";
import {
	IconUserPlus,
} from "@tabler/icons-react";
import { ListSubheader, styled, type Theme } from "@mui/material";
import { uniqueId } from "lodash";
import Logo from "../player/components/Logo";
import { useAuthenticatedUser } from "./firebase";

const PREFIX = "/dashboard";

const Menuitems = [
	{
		id: uniqueId(),
		title: "Home",
		icon: IconHome,
		href: "/home",
	},
	{
		navlabel: true,
		subheader: "Estrutura",
	},
	{
		id: uniqueId(),
		title: "Grupos",
		icon: IconBinaryTreeFilled,
		href: "/groups",
	},
	{
		id: uniqueId(),
		title: "Categorias",
		icon: IconLabelFilled,
		href: "/categories",
	},
	{
		id: uniqueId(),
		title: "Players",
		icon: IconDeviceTv,
		href: "/players",
	},
	{
		navlabel: true,
		subheader: "Conteúdo",
	},
	{
		id: uniqueId(),
		title: "Vídeos",
		icon: IconMovie,
		href: "/videos",
	},
	{
		id: uniqueId(),
		title: "Embed",
		icon: IconCode,
		href: "/embed",
	},
	{
		navlabel: true,
		subheader: "Acessos",
	},
	{
		id: uniqueId(),
		title: "Usuários",
		icon: IconUserPlus,
		href: "/users",
	},
];

const SidebarItems = ({ toggleMobileSidebar }: any) => {
	const location = useLocation();
	const pathDirect = location.pathname;

	return (
		<Box sx={{ px: 3 }}>
			<List sx={{ pt: 0 }} className="sidebarNav" component="div">
				{Menuitems.map((item) => {
					if (item.subheader) {
						return <NavGroup item={item} key={item.subheader} />;
					} else {
						return (
							<NavItem
								item={item}
								prefix={PREFIX}
								key={item.id}
								pathDirect={pathDirect}
								onClick={toggleMobileSidebar}
							/>
						);
					}
				})}
			</List>
		</Box>
	);
};

interface CustomSidebarType {
	isMobileSidebarOpen: boolean;
	onSidebarClose: (event: React.MouseEvent<HTMLElement>) => void;
	isSidebarOpen: boolean;
}

const CustomSidebar = ({
	isMobileSidebarOpen,
	onSidebarClose,
	isSidebarOpen,
}: CustomSidebarType) => {
	const lgUp = true; //useMediaQuery((theme: any) => theme.breakpoints.up("lg"));

	const sidebarWidth = "270px";

	// Custom CSS for short scrollbar
	const scrollbarStyles = {
		"&::-webkit-scrollbar": {
			width: "7px",
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "#eff2f7",
			borderRadius: "15px",
		},
	};

	if (lgUp) {
		return (
			<Box
				sx={{
					width: sidebarWidth,
					flexShrink: 0,
				}}
			>
				<Drawer
					anchor="left"
					open={isSidebarOpen}
					variant="permanent"
					PaperProps={{
						sx: {
							boxSizing: "border-box",
							...scrollbarStyles,
						},
					}}
				>
					<Box
						sx={{
							height: "100%",
						}}
					>
						<Sidebar
							width={"270px"}
							collapsewidth="80px"
							open={isSidebarOpen}
							themeColor="#5d87ff"
							themeSecondaryColor="#49beff"
							showProfile={false}
						>
							<Logo />
							<Box>
								<SidebarItems />
							</Box>
						</Sidebar>
					</Box>
				</Drawer>
			</Box>
		);
	}

	return (
		<Drawer
			anchor="left"
			open={isMobileSidebarOpen}
			onClose={onSidebarClose}
			variant="temporary"
			PaperProps={{
				sx: {
					boxShadow: (theme) => theme.shadows[8],
					...scrollbarStyles,
				},
			}}
		>
			{/* ------------------------------------------- */}
			{/* Sidebar Box */}
			{/* ------------------------------------------- */}
			<Box px={2}>
				<Sidebar
					width={"270px"}
					collapsewidth="80px"
					isCollapse={false}
					mode="light"
					direction="ltr"
					themeColor="#5d87ff"
					themeSecondaryColor="#49beff"
					showProfile={false}
				>
					<Logo />
					<SidebarItems />
					<Upgrade />
				</Sidebar>
			</Box>
		</Drawer>
	);
};

type NavGroup = {
	navlabel?: boolean;
	subheader?: string;
};

interface NavGroupProps {
	item: NavGroup;
}

const NavGroup = ({ item }: NavGroupProps) => {
	const ListSubheaderStyle = styled((props: Theme | any) => (
		<ListSubheader disableSticky {...props} />
	))(({ theme }) => ({
		...theme.typography.overline,
		fontWeight: "700",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(0),
		color: theme.palette.text.primary,
		lineHeight: "26px",
		padding: "3px 12px",
	}));
	return <ListSubheaderStyle>{item.subheader}</ListSubheaderStyle>;
};

type NavGroupType = {
	[x: string]: any;
	id?: string;
	navlabel?: boolean;
	subheader?: string;
	title?: string;
	icon?: any;
	href?: any;
	onClick?: React.MouseEvent<HTMLButtonElement, MouseEvent>;
};

interface NavItemType {
	item: NavGroupType;
	onClick: (event: React.MouseEvent<HTMLElement>) => void;
	prefix?: string;
	hideMenu?: any;
	level?: number | any;
	pathDirect: string;
}

const NavItem = ({
	item,
	level,
	pathDirect,
	onClick,
	prefix = "/",
}: NavItemType) => {
	const Icon = item.icon;
	const theme = useTheme();
	const itemIcon = <Icon stroke={1.5} size="1.3rem" />;
	const finalUrl = `${prefix}${item.href}`;
	const ListItemStyled = styled(ListItem)(() => ({
		padding: 0,
		".MuiButtonBase-root": {
			whiteSpace: "nowrap",
			marginBottom: "2px",
			padding: "8px 10px",
			borderRadius: "8px",
			backgroundColor: level > 1 ? "transparent !important" : "inherit",
			color: theme.palette.text.secondary,
			paddingLeft: "10px",
			"&:hover": {
				backgroundColor: theme.palette.primary.light,
				color: theme.palette.primary.main,
			},
			"&.Mui-selected": {
				color: "white",
				backgroundColor: theme.palette.primary.main,
				"&:hover": {
					backgroundColor: theme.palette.primary.main,
					color: "white",
				},
			},
		},
	}));

	return (
		<List component="div" disablePadding key={item.id}>
			<ListItemStyled>
				<ListItemButton
					component={Link}
					to={finalUrl}
					disabled={item.disabled}
					selected={pathDirect === finalUrl}
					target={item.external ? "_blank" : ""}
					onClick={onClick}
					key={item.id}
				>
					<ListItemIcon
						sx={{
							minWidth: "36px",
							p: "3px 0",
							color: "inherit",
						}}
					>
						{itemIcon}
					</ListItemIcon>
					<ListItemText>
						<>{item.title}</>
					</ListItemText>
				</ListItemButton>
			</ListItemStyled>
		</List>
	);
};

interface HeaderProps {
	toggleMobileSidebar: (event: React.MouseEvent<HTMLElement>) => void;
}

export const Header = ({ toggleMobileSidebar }: HeaderProps) => {
	const authenticatedUser = useAuthenticatedUser();

	// const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
	// const lgDown = useMediaQuery((theme) => theme.breakpoints.down('lg'));

	const AppBarStyled = styled(AppBar)(({ theme }) => ({
		boxShadow: "none",
		background: theme.palette.background.paper,
		justifyContent: "center",
		backdropFilter: "blur(4px)",
		[theme.breakpoints.up("lg")]: {
			minHeight: "70px",
		},
	}));
	const ToolbarStyled = styled(Toolbar)(({ theme }) => ({
		width: "100%",
		color: theme.palette.text.secondary,
	}));

	return (
		<AppBarStyled position="sticky" color="default">
			<ToolbarStyled>
				<IconButton
					color="inherit"
					aria-label="menu"
					onClick={toggleMobileSidebar}
					sx={{
						display: {
							lg: "none",
							xs: "inline",
						},
					}}
				>
					<IconMenu width="20" height="20" />
				</IconButton>

				<Box flexGrow={1} />
				<Stack spacing={1} direction="row" alignItems="center">
					<IconButton onClick={authenticatedUser.logout}>
						<IconLogout width={20} height={20} />
					</IconButton>
					<IconButton size="large" color="inherit">
						<Avatar
							src={authenticatedUser.user?.photoURL}
							alt={authenticatedUser.user?.displayName}
							sx={{
								width: 35,
								height: 35,
							}}
						/>
					</IconButton>
				</Stack>
			</ToolbarStyled>
		</AppBarStyled>
	);
};

const MainWrapper = styled("div")(() => ({
	display: "flex",
	minHeight: "100vh",
	width: "100%",
}));

const PageWrapper = styled("div")(() => ({
	display: "flex",
	flexGrow: 1,
	paddingBottom: "60px",
	flexDirection: "column",
	zIndex: 1,
	backgroundColor: "transparent",
}));

export function RootLayout({
	children,
}: {
	children: React.ReactNode;
}) {
	const [isSidebarOpen, setSidebarOpen] = useState(true);
	const [isMobileSidebarOpen, setMobileSidebarOpen] = useState(false);
	return (
		<MainWrapper className="mainwrapper">
			{/* ------------------------------------------- */}
			{/* Sidebar */}
			{/* ------------------------------------------- */}
			<CustomSidebar
				isSidebarOpen={isSidebarOpen}
				isMobileSidebarOpen={isMobileSidebarOpen}
				onSidebarClose={() => setMobileSidebarOpen(false)}
			/>
			{/* ------------------------------------------- */}
			{/* Main Wrapper */}
			{/* ------------------------------------------- */}
			<PageWrapper className="page-wrapper">
				{/* ------------------------------------------- */}
				{/* Header */}
				{/* ------------------------------------------- */}
				<Header toggleMobileSidebar={() => setMobileSidebarOpen(true)} />
				{/* ------------------------------------------- */}
				{/* PageContent */}
				{/* ------------------------------------------- */}
				<Container
					sx={{
						paddingTop: "20px",
						maxWidth: "1200px",
					}}
				>
					{/* ------------------------------------------- */}
					{/* Page Route */}
					{/* ------------------------------------------- */}
					<Box sx={{ minHeight: "calc(100vh - 170px)" }}>{children}</Box>
					{/* ------------------------------------------- */}
					{/* End Page */}
					{/* ------------------------------------------- */}
				</Container>
			</PageWrapper>
		</MainWrapper>
	);
}
