import { Box, CircularProgress } from "@mui/material";
import { useEmbedByName } from "../hooks/useEmbedByName";

const EmbedBox = ({
	html,
	children,
}: { html?: string; children?: React.ReactNode }) => (
	<Box
		className="w-full h-full flex-grow bg-black/40 text-white flex items-center justify-center"
		// biome-ignore lint/security/noDangerouslySetInnerHtmlWithChildren: <explanation>
		// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
		dangerouslySetInnerHTML={
			html
				? {
						__html: html,
					}
				: undefined
		}
	>
		{children}
	</Box>
);

export default function EmbedPage({ name }: { name: string }) {
	const embedQuery = useEmbedByName(name);

	if (embedQuery.isLoading)
		return (
			<EmbedBox>
				<CircularProgress />
			</EmbedBox>
		);
	if (embedQuery.isError) return <div>Error</div>;
	if (!embedQuery.data) return <div>Not found</div>;

	return <EmbedBox html={embedQuery.data.html} />;
}
