import { useNavigate } from "react-router-dom";
import Logo from "./Logo";

import homeButton from "../../assets/home.png";
import voltarButton from "../../assets/voltar.png";
import { useVideoPlayerStore } from "../../utils/store";
import { useAnalytics } from "../hooks/useAnalytics";
import { playerActivationStore } from "../../utils/firebase";

export function Header() {
	const videoPlayer = useVideoPlayerStore();
	const navigate = useNavigate();
	const analytics = useAnalytics();
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const backPath: any =
		window.location.pathname === "/player/treinamento/uconline" ? -2 : -1;

	const handleBackClick = () => {
		analytics.click(playerActivationStore.activationPassword!, "voltar");

		if (videoPlayer.isPlaying) {
			videoPlayer.stop();
			return;
		}

		navigate(backPath);
	};

	const handleHomeClick = () => {
		analytics.click(playerActivationStore.activationPassword!, "home");

		if (videoPlayer.isPlaying) {
			videoPlayer.stop();
		}

		navigate("/player");
	};

	return (
		<header className="bg-gradient-to-r from-[#00336bff] via-[#005196ff] to-[#00336bff] shadow-md">
			<div className="mx-auto flex items-center justify-between py-[1vw] px-[5vw]">
				<nav className="flex sm:-ml-8 md:ml-0">
					<button
						onClick={handleHomeClick}
						className="rounded-full bg-black px-[2vw] py-[1vw] hover:bg-opacity-20 bg-opacity-0 transition-all"
					>
						<img className="h-[3vh]" src={homeButton} alt="CondorFlix" />
					</button>
					<button
						className="-ml-[1vw] rounded-full bg-black px-[2vw] py-[1vw] hover:bg-opacity-20 bg-opacity-0 transition-all"
						onClick={handleBackClick}
					>
						<img className="h-[3vh]" src={voltarButton} alt="Voltar" />
					</button>
				</nav>
				<div>
					<Logo white className="h-[7vh]" />
				</div>
			</div>
		</header>
	);
}
