/* eslint-disable react-refresh/only-export-components */
import {
	type User,
	onAuthStateChanged,
} from "firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../utils/firebase";
import { useNavigate } from "react-router-dom";
import React from "react";

export const FirebaseAuthContext = React.createContext<{
	user: User | null;
	logout: () => Promise<void>;
	loading: boolean;
}>(null as unknown as any);

export const FirebaseAuthProvider = ({ children }) => {
	const [user, setUser] = useState<User | null>(auth.currentUser);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	const logout = async () => {
		await auth.signOut();
		setUser(null);
		navigate("/");
	};

	useEffect(() => {
		setLoading(true);

		const listener = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
			}
			setLoading(false);
		});
		return () => {
			listener();
		};
	}, []);

	return (
		<FirebaseAuthContext.Provider value={{ user, logout, loading }}>
			{children}
		</FirebaseAuthContext.Provider>
	);
};

export const useAuthenticatedUser = () => {
	return React.useContext(FirebaseAuthContext);
};
