import { motion } from "framer-motion";

export const MotionMain = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => {
	return (
		<motion.main
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className={className}
		>
			{children}
		</motion.main>
	);
};
