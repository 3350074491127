import { Box, Drawer } from "@mui/material";
import type { ReactNode } from "react";

interface SidepaneProps {
	children: ReactNode;
	open: boolean;
	toggle: (open: boolean) => void;
}

export default function Sidepane(props: SidepaneProps) {
	const { children, open, toggle } = props;

	return (
		<>
			<Drawer anchor={"right"} open={open} onClose={() => toggle(false)}>
				<Box sx={{ width: "600px", padding: "20px" }}>{children}</Box>
			</Drawer>
		</>
	);
}
