import {
	type FormEvent,
	useCallback,
	useEffect,
	useMemo,
	useState,
} from "react";
import { Stack } from "@mui/material";
import { applyPagination } from "../../utils/applyPagination";
import Sidepane from "../components/Sidepane";
import {
	createEmbed,
	deleteEmbedById,
	listEmbeds,
	updateEmbed,
} from "../../utils/firebase";
import EmbedTable from "../components/EmbedTable";
import EmbedDetails from "../components/EmbedDetails";
import type { IEmbed } from "../../entities/embed.entity";
import { useSidePane } from "../hook/useSidePane";
import { CrudContainer } from "../components/CrudContainer";
import { captureException } from "@sentry/react";

const useEmbeds = (page: number, rowsPerPage: number, data: unknown) => {
	return useMemo(() => {
		return applyPagination(data, page, rowsPerPage);
	}, [page, rowsPerPage, data]);
};

export default function Embeds() {
	const [embedDetail, setEmbedDetail] = useState<IEmbed | undefined>(undefined);
	const [data, setData] = useState<IEmbed[] | []>([]);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const embeds = useEmbeds(page, rowsPerPage, data);
	const sidepane = useSidePane({
		onClose: () => setEmbedDetail(undefined),
	});

	async function fetchData() {
		try {
			const data = await listEmbeds();
			setData(data);
		} catch (error) {
			captureException(error);
			console.error(error);
		}
	}

	useEffect(() => {
		fetchData();
	}, []);

	const handlePageChange = useCallback((_event, value: number) => {
		setPage(value);
	}, []);

	const handleRowsPerPageChange = useCallback((event) => {
		setRowsPerPage(event.target.value);
	}, []);

	async function handleRemoveEmbed(id: string) {
		await deleteEmbedById(id);
		fetchData();
	}

	function handleUpdateEmbed(id: string) {
		if (data.length > 0) {
			for (const embed of data) {
				if (embed.id === id) {
					setEmbedDetail(embed);

					sidepane.setOpen(true);
				}
			}
		}
	}

	const handleSubmitEmbed = async (
		event: FormEvent<HTMLFormElement>,
		data: IEmbed,
	) => {
		event.preventDefault();

		if (data.id) {
			await updateEmbed(data);
			fetchData();
			sidepane.setOpen(false);
			return;
		}

		await createEmbed(data);

		fetchData();
		sidepane.setOpen(false);
	};

	return (
		<>
			<CrudContainer
				title="Embeds"
				onClickAdd={() => sidepane.setOpen(true)}
				subtitle="Aqui você pode gerenciar os conteúdos incorporados"
				empty={data.length === 0}
			>
				<Stack spacing={3}>
					{data.length > 0 && (
						<EmbedTable
							count={data.length}
							items={embeds}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleRowsPerPageChange}
							onRemoveItem={handleRemoveEmbed}
							onUpdateItem={handleUpdateEmbed}
							page={page}
							rowsPerPage={rowsPerPage}
						/>
					)}
				</Stack>
			</CrudContainer>

			<Sidepane open={sidepane.isOpen} toggle={sidepane.toggle}>
				<EmbedDetails
					item={embedDetail}
					handleSubmitEmbed={handleSubmitEmbed}
				/>
			</Sidepane>
		</>
	);
}
