import { useQuery } from "@tanstack/react-query";
import { listUsers } from "../../utils/firebase";

export function useUsersList() {
	return useQuery({
		queryKey: ["users", "list"],
		async queryFn() {
			const data = await listUsers();
			return data;
		},
	});
}
