import { Navigate } from "react-router-dom";
import { useAuthenticatedUser } from "./firebase";

export const ProtectedRoute = ({ children }) => {
	const authenticated = useAuthenticatedUser();

	if (authenticated.loading) {
		// loading
		return (
			<>
				<h1>loading...</h1>
				{children}
			</>
		);
	}

	if (!authenticated.user) {
		// user is not authenticated
		return <Navigate to="/dashboard/login" />;
	}

	return children;
};
