import {
	Box,
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";

interface IEmbed {
	id: string;
	name: string;
	description: string;
	html: string;
	createdAt: string;
}

interface embedTableProps {
	count: number;
	items: IEmbed[];
	onPageChange: (event, value: number) => void;
	onRowsPerPageChange: (event) => void;
	onRemoveItem: (id: string) => void;
	onUpdateItem: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function EmbedTable(props: embedTableProps) {
	const {
		count = 0,
		items = [],
		onPageChange = () => {},
		onRowsPerPageChange,
		onUpdateItem,
		page = 0,
		rowsPerPage = 0,
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell>HTML</TableCell>
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => {
						return (
							<TableRow hover key={item.id}>
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">{item.name}</Typography>
									</Stack>
								</TableCell>
								<TableCell>
									<Box sx={{ maxHeight: 50, overflow: "auto" }}>
										{item.html}
									</Box>
								</TableCell>
								<TableCell align="center" size="small">
									<Stack
										sx={{
											display: "inline-flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<IconButton
											aria-label="edit"
											onClick={() => onUpdateItem(item.id)}
											color="primary"
										>
											<SvgIcon>
												<PencilIcon />
											</SvgIcon>
										</IconButton>
									</Stack>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
