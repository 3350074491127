import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
	CircularProgress,
	type SelectChangeEvent,
} from "@mui/material";
import {
	useState,
	useCallback,
	type FormEvent,
	type ChangeEvent,
	type ChangeEventHandler,
} from "react";
import type { IPlayer } from "../../entities/player.entity";
import { GroupInput } from "./GroupInput";

interface PlayerDetailsProps {
	item?: IPlayer;
	isSaving?: boolean;
	handleSubmitPlayer: (
		event: FormEvent<HTMLFormElement>,
		data: IPlayer,
	) => void;
}

export default function PlayerDetails(props: PlayerDetailsProps) {
	const { item, handleSubmitPlayer } = props;

	const [values, setValues] = useState<IPlayer>(
		item || {
			id: "",
			name: "",
			password: "",
			createdAt: "",
			groups: [],
		},
	);

	const handleChange = useCallback(
		(
			event:
				| ChangeEvent<HTMLInputElement>
				| SelectChangeEvent<string[]>
				| ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
		) => {
			setValues((prevState) => ({
				...prevState,
				[event.target.name]: event.target.value,
			}));
		},
		[],
	);

	const isFormInvalid = !values.password;

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Player</Typography>
			</div>

			<form
				autoComplete="off"
				noValidate
				onSubmit={(e) => handleSubmitPlayer(e, values)}
			>
				<Grid container spacing={3}>
					<Grid xs={12}>
						<TextField
							fullWidth
							helperText="Nome da senha"
							label="Nome"
							name="name"
							onChange={handleChange}
							required
							value={values.name}
						/>
					</Grid>
					<Grid xs={12}>
						<TextField
							fullWidth
							helperText="Digite uma senha"
							label="Senha"
							name="password"
							onChange={handleChange}
							required
							value={values.password}
						/>
					</Grid>
					<Grid xs={12}>
						<GroupInput value={values.groups} onChange={handleChange} />
					</Grid>
					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
							endIcon={props.isSaving ? <CircularProgress /> : undefined}
							disabled={isFormInvalid || props.isSaving}
						>
							SALVAR
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
