import {
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import DeleteButtonIcon from "./DeleteButtonIcon";
import type { ICategory } from "../../entities/categorie.entity";
import type { useCategoryRemover } from "../hook/useCategoryRemover";
import { LOOP_CATEGORIES_NAME } from "../../utils/constants";

interface CategorieTableProps {
	count: number;
	items: ICategory[];
	onPageChange: (event, value: number) => void;
	onRowsPerPageChange: (event) => void;
	categoryRemover: ReturnType<typeof useCategoryRemover>;
	onUpdateCategorie: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function CategorieTable(props: CategorieTableProps) {
	const {
		count = 0,
		items = [] as ICategory[],
		onPageChange = () => {},
		onRowsPerPageChange,
		categoryRemover,
		onUpdateCategorie,
		page = 0,
		rowsPerPage = 0,
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((categorie) => {
						return (
							<TableRow hover key={categorie.id}>
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">
											{categorie.name}
										</Typography>
									</Stack>
								</TableCell>
								<TableCell size="small">
									{categorie.name === LOOP_CATEGORIES_NAME ? null : (
										<Stack
											sx={{
												display: "inline-flex",
												flexDirection: "row",
												gap: "10px",
											}}
										>
											<IconButton
												aria-label="edit"
												color="primary"
												onClick={() => onUpdateCategorie(categorie.id)}
											>
												<SvgIcon>
													<PencilIcon />
												</SvgIcon>
											</IconButton>
											<DeleteButtonIcon
												onClick={() => categoryRemover.mutate(categorie)}
												loading={
													categoryRemover.isLoading &&
													categoryRemover.variables?.id === categorie.id
												}
												disabled={categoryRemover.isLoading}
											/>
										</Stack>
									)}
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
