import * as firebaseui from "firebaseui";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import "firebaseui/dist/firebaseui.css";
import { useEffect } from "react";
import { useAuthenticatedUser } from "../firebase";

export const ui = new firebaseui.auth.AuthUI(getAuth());

export const FirebaseLogin = () => {
	const authenticated = useAuthenticatedUser();

	useEffect(() => {
		if (authenticated.loading) {
			return;
		}

		ui.start("#firebaseui-auth-container", {
			signInOptions: [GoogleAuthProvider.PROVIDER_ID],
		});
	}, [authenticated.loading]);

	if (authenticated.loading) {
		return <h1>Authenticating...</h1>;
	}

	return <div id="firebaseui-auth-container"></div>;
};
