import { useMutation } from "@tanstack/react-query";
import type { IVideo, ISplashVideo } from "../../entities/video.entity";
import { deleteVideoById } from "../../utils/firebase";

export const useVideoRemover = ({
	onSuccess,
	onError,
}: {
	onSuccess: () => void;
	onError: (...args: any[]) => void;
}) => {
	return useMutation({
		mutationKey: ["videos", "remove"],
		mutationFn: async (video: IVideo | ISplashVideo) => {
			return deleteVideoById(video.id);
		},
		onSuccess,
		onError,
	});
};
