import ucBg from "../../assets/buttons/tv_zonta_uconline.svg";
import { BigButton } from "./BigButton";
import { openUrl } from "./openUrl";

export const UcOnlineButton = () => {
	return (
		<BigButton
			label="UC Online"
			backgroundUrl={ucBg}
			onClick={() => {
				openUrl("https://universidade.condor.com.br/");
			}}
		/>
	);
};
