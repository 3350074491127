import {
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import DeleteButtonIcon from "./DeleteButtonIcon";
import type { useGroupRemover } from "../hook/useGroupRemover";
import type { IGroup } from "../../entities/group.entity";

interface GroupTableProps {
	count: number;
	items: IGroup[];
	onPageChange: (event, value: number) => void;
	onRowsPerPageChange: (event) => void;
	groupRemover: ReturnType<typeof useGroupRemover>;
	onUpdateGroup: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function GroupTable(props: GroupTableProps) {
	const {
		count = 0,
		items = [] as IGroup[],
		onPageChange = () => {},
		onRowsPerPageChange,
		groupRemover,
		onUpdateGroup,
		page = 0,
		rowsPerPage = 0,
	} = props;

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Nome</TableCell>
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((group) => {
						return (
							<TableRow hover key={group.id}>
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">{group.name}</Typography>
									</Stack>
								</TableCell>
								<TableCell size="small">
									<Stack
										sx={{
											display: "inline-flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<IconButton
											aria-label="edit"
											color="primary"
											onClick={() => onUpdateGroup(group.id)}
										>
											<SvgIcon>
												<PencilIcon />
											</SvgIcon>
										</IconButton>
										<DeleteButtonIcon
											onClick={() => groupRemover.mutate(group)}
											loading={
												groupRemover.isLoading &&
												groupRemover.variables?.id === group.id
											}
											disabled={groupRemover.isLoading}
										/>
									</Stack>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
