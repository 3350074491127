/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-refresh/only-export-components */
import { type FormEvent, useCallback, useMemo, useState } from "react";
import { applyPagination } from "../../utils/applyPagination";
import Sidepane from "../components/Sidepane";
import { useSidePane } from "../hook/useSidePane";
import type { IUser } from "../../entities/user.entity";
import UserTable from "../components/UserTable";
import UserDetails from "../components/UserDetail";
import { useUserRemover } from "../hook/useUsersRemover";
import { useUsersList } from "../hook/useUsersList";
import { CrudContainer } from "../components/CrudContainer";

const useUsersPaginated = (
	page: number,
	rowsPerPage: number,
	data: unknown,
) => {
	return useMemo(() => {
		return applyPagination(data, page, rowsPerPage);
	}, [page, rowsPerPage, data]);
};

export default function Users({ collectionTitle = "Usuários" }) {
	const [userDetail, setUserDetail] = useState<IUser | undefined>(undefined);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const usersQuery = useUsersList();
	const users = useUsersPaginated(page, rowsPerPage, usersQuery.data || []);
	const sidepane = useSidePane({
		onClose: () => setUserDetail(undefined),
	});

	const userRemover = useUserRemover();

	const handlePageChange = useCallback<
		NonNullable<Parameters<typeof UserTable>[0]["onPageChange"]>
	>((_, value: number) => {
		setPage(value);
	}, []);

	const handleRowsPerPageChange = useCallback<
		NonNullable<Parameters<typeof UserTable>[0]["onRowsPerPageChange"]>
	>((event) => {
		setRowsPerPage(event.target.value);
	}, []);

	function handleUpdateUser(id: string) {
		if (!usersQuery.data) return;

		for (const user of usersQuery.data) {
			if (user.id === id) {
				setUserDetail(user);
				sidepane.setOpen(true);
			}
		}
	}

	const handleUserChanged = async () => {
		sidepane.setOpen(false);
	};

	return (
		<>
			<CrudContainer
				title={collectionTitle}
				onClickAdd={() => sidepane.setOpen(true)}
				subtitle="Gerencie os usuários do sistema"
				empty={!usersQuery.data}
			>
				{/* <TableSearch /> */}
				{users.length > 0 && (
					<UserTable
						count={usersQuery.data?.length || 0}
						items={users}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						userRemover={userRemover}
						onUpdateUser={handleUpdateUser}
						page={page}
						rowsPerPage={rowsPerPage}
					/>
				)}
			</CrudContainer>

			<Sidepane open={sidepane.isOpen} toggle={sidepane.toggle}>
				<UserDetails item={userDetail} onUserChanged={handleUserChanged} />
			</Sidepane>
		</>
	);
}
