/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-refresh/only-export-components */
import { type FormEvent, useCallback, useMemo, useState } from "react";
import { Stack } from "@mui/material";
import { applyPagination } from "../../utils/applyPagination";
import Sidepane from "../components/Sidepane";
import {
	firebaseAddFirestore,
	firebaseUpdateFirestore,
} from "../../utils/firebase";
import { useSidePane } from "../hook/useSidePane";
import type { IGroup } from "../../entities/group.entity";
import GroupTable from "../components/GroupTable";
import GroupDetails from "../components/GroupDetail";
import { useGroupRemover } from "../hook/useGroupRemover";
import { useGroupsList } from "../hook/useGroupsList";
import { CrudContainer } from "../components/CrudContainer";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

const useGroupsPaginated = (
	page: number,
	rowsPerPage: number,
	data: unknown,
) => {
	return useMemo(() => {
		return applyPagination(data, page, rowsPerPage);
	}, [page, rowsPerPage, data]);
};

export default function Groups({
	collectionName = "groups",
	collectionTitle = "Grupos",
}) {
	const [groupDetail, setGroupDetail] = useState<IGroup | undefined>(undefined);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const groupsQuery = useGroupsList();
	const groups = useGroupsPaginated(page, rowsPerPage, groupsQuery.data || []);
	const sidepane = useSidePane({
		onClose: () => setGroupDetail(undefined),
	});

	const groupRemover = useGroupRemover();

	const handlePageChange = useCallback<
		NonNullable<Parameters<typeof GroupTable>[0]["onPageChange"]>
	>((_, value: number) => {
		setPage(value);
	}, []);

	const handleRowsPerPageChange = useCallback<
		NonNullable<Parameters<typeof GroupTable>[0]["onRowsPerPageChange"]>
	>((event) => {
		setRowsPerPage(event.target.value);
	}, []);

	function handleUpdateGroup(id: string) {
		if (!groupsQuery.data) return;

		for (const group of groupsQuery.data) {
			if (group.id === id) {
				setGroupDetail(group);
				sidepane.setOpen(true);
			}
		}
	}

	const handleSubmitGroup = async (
		event: FormEvent<HTMLFormElement>,
		data: IGroup,
	) => {
		event.preventDefault();

		try {
			if (data.id) {
				await firebaseUpdateFirestore(collectionName, data);
			} else {
				await firebaseAddFirestore(collectionName, {
					...data,
					createdAt: new Date(),
				});
			}

			await groupsQuery.refetch();

			enqueueSnackbar({
				variant: "success",
				message: "Grupo salvo com sucesso",
			});
		} catch (error) {
			console.error(error);
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao salvar grupo, tente novamente",
			});
		}

		sidepane.setOpen(false);
	};

	return (
		<>
			<CrudContainer
				title={collectionTitle}
				subtitle="Gerencie os grupos"
				onClickAdd={() => sidepane.setOpen(true)}
				empty={groupsQuery.data?.length === 0}
			>
				<Stack spacing={3}>
					{/* <TableSearch /> */}
					{groups.length > 0 && (
						<GroupTable
							count={groups.length}
							items={groups}
							onPageChange={handlePageChange}
							onRowsPerPageChange={handleRowsPerPageChange}
							groupRemover={groupRemover}
							onUpdateGroup={handleUpdateGroup}
							page={page}
							rowsPerPage={rowsPerPage}
						/>
					)}
				</Stack>
			</CrudContainer>
			<Sidepane open={sidepane.isOpen} toggle={sidepane.toggle}>
				<GroupDetails
					item={groupDetail}
					handleSubmitGroup={handleSubmitGroup}
				/>
			</Sidepane>
		</>
	);
}
