/* eslint-disable @typescript-eslint/no-explicit-any */
import {
	IconButton,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@mui/material";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import DeleteButtonIcon from "./DeleteButtonIcon";
import type { ISplashVideo, IVideo } from "../../entities/video.entity";
import defaultVideoImage from "../../assets/default-video-image.webp";
import { isIVideo } from "../hook/useVideoManager";
import type { useVideoRemover } from "../hook/useVideoRemover";
import type { ICategory } from "../../entities/categorie.entity";

interface VideoTableProps {
	count: number;
	items: ((IVideo | ISplashVideo) & { categories: ICategory[] })[];
	onPageChange: (event: any, value: number) => void;
	onRowsPerPageChange: (event: any) => void;
	videoRemover: ReturnType<typeof useVideoRemover>;
	onUpdateItem: (id: string) => void;
	page: number;
	rowsPerPage: number;
}

export default function VideosTable(props: VideoTableProps) {
	const {
		count = 0,
		items = [],
		onPageChange = () => {},
		onRowsPerPageChange,
		videoRemover,
		onUpdateItem,
		page = 0,
		rowsPerPage = 0,
	} = props;

	const isCompleteVideo = isIVideo(items?.[0]);
	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>Thumbnail</TableCell>
						<TableCell>Título</TableCell>
						{isCompleteVideo && (
							<>
								<TableCell>Categoria</TableCell>
								<TableCell>Duração</TableCell>
							</>
						)}
						<TableCell size="small">Ação</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{items.map((item) => {
						const isCompleteVideo = isIVideo(item);
						return (
							<TableRow hover key={item.id}>
								<TableCell
									style={{
										backgroundImage: `url(${isCompleteVideo ? item.thumbnail.url : defaultVideoImage})`,
										backgroundSize: "contain",
										backgroundPosition: "center",
										backgroundRepeat: "no-repeat",
										height: "100px",
									}}
								/>
								<TableCell>
									<Stack alignItems="center" direction="row" spacing={2}>
										<Typography variant="subtitle2">{item.title}</Typography>
									</Stack>
								</TableCell>
								<TableCell align="center" size="small">
									<Stack
										sx={{
											display: "inline-flex",
											flexDirection: "row",
											gap: "10px",
										}}
									>
										<IconButton
											aria-label="edit"
											onClick={() => onUpdateItem(item.id)}
											color="primary"
										>
											<SvgIcon>
												<PencilIcon />
											</SvgIcon>
										</IconButton>
										<DeleteButtonIcon
											onClick={() => videoRemover.mutate(item)}
											loading={
												videoRemover.isLoading &&
												videoRemover.variables?.id === item.id
											}
											disabled={videoRemover.isLoading}
										/>
									</Stack>
								</TableCell>
							</TableRow>
						);
					})}
				</TableBody>
			</Table>
			<TablePagination
				component="div"
				count={count}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
				page={page}
				rowsPerPage={rowsPerPage}
				rowsPerPageOptions={[5, 10, 25]}
			/>
		</>
	);
}
