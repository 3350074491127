"use client";
import { Grid, Box, Card, Typography } from "@mui/material";
import { Navigate } from "react-router-dom";
import { useAuthenticatedUser } from "../firebase";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Logo from "../../player/components/Logo";
import { FirebaseLogin } from "../config/firebaseUi";

interface loginType {
	title?: string;
	subtitle?: JSX.Element | JSX.Element[];
	subtext?: JSX.Element | JSX.Element[];
}

type Props = {
	description?: string;
	children: JSX.Element | JSX.Element[];
	title?: string;
};

export const PageContainer = ({ title, description, children }: Props) => (
	<HelmetProvider>
		<div>
			<Helmet>
				<title>{title}</title>
				<meta name="description" content={description} />
			</Helmet>
			{children}
		</div>
	</HelmetProvider>
);

const AuthLogin = ({ title, subtitle, subtext }: loginType) => (
	<>
		{title ? (
			<Typography fontWeight="700" variant="h2" mb={1}>
				{title}
			</Typography>
		) : null}

		{subtext}

		<Box>
			<FirebaseLogin />
		</Box>
		{subtitle}
	</>
);

export default function LoginPage() {
	const userAuthenticated = useAuthenticatedUser();

	if (userAuthenticated.user) {
		return <Navigate to="/dashboard/home" />;
	}

	return (
		<PageContainer title="Login" description="this is Login page">
			<Box
				sx={{
					position: "relative",
					"&:before": {
						content: '""',
						background: "radial-gradient(#d2f1df, #d3d7fa, #bad8f4)",
						backgroundSize: "400% 400%",
						animation: "gradient 15s ease infinite",
						position: "absolute",
						height: "100%",
						width: "100%",
						opacity: "0.3",
					},
				}}
			>
				<Grid
					container
					spacing={0}
					justifyContent="center"
					sx={{ height: "100vh" }}
				>
					<Grid
						item
						xs={12}
						sm={12}
						lg={4}
						xl={3}
						display="flex"
						justifyContent="center"
						alignItems="center"
					>
						<Card
							elevation={9}
							sx={{ p: 4, zIndex: 1, width: "100%", maxWidth: "500px" }}
						>
							<Box display="flex" alignItems="center" justifyContent="center">
								<Logo />
							</Box>
							<AuthLogin />
						</Card>
					</Grid>
				</Grid>
			</Box>
		</PageContainer>
	);
}
