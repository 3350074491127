const IS_PROD = window.location.href.includes("https://");
const IS_DEBUG_EXPLICITLY_DISABLED = localStorage.getItem("debug") === "false";
const IS_DEBUG_ENABLED = localStorage.getItem("debug") === "true";
export const IS_DEV_DEBUG =
	!IS_DEBUG_EXPLICITLY_DISABLED && (!IS_PROD || IS_DEBUG_ENABLED);

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;

export const LOOP_CATEGORIES_NAME = "tv_mural";
export const REACT_QUERY_CACHE_TIME = IS_DEV_DEBUG ? 3 * MINUTE : 365 * DAY;
export const REACT_QUERY_STALE_TIME = IS_DEV_DEBUG ? 30 * SECOND : 1 * MINUTE;
export const INACTIVE_TIMEOUT = IS_DEV_DEBUG ? 3 * MINUTE : 45 * SECOND;
export const LOOP_VIDEOS_REFETCH_INTERVAL = IS_DEV_DEBUG
	? 30 * SECOND
	: 5 * MINUTE;
export const ANALYTICS_PING_INTERVAL = 1000 * 30;

console.log({
	REACT_QUERY_CACHE_TIME: `${REACT_QUERY_CACHE_TIME / MINUTE} minutes`,
	REACT_QUERY_STALE_TIME: `${REACT_QUERY_STALE_TIME / MINUTE} minutes`,
	INACTIVE_TIMEOUT: `${INACTIVE_TIMEOUT / SECOND} seconds`,
	LOOP_VIDEOS_REFETCH_INTERVAL: `${LOOP_VIDEOS_REFETCH_INTERVAL / MINUTE} minutes`,
	IS_DEV_DEBUG,
});

export const CATEGORY_COLLECTION_NAME = {
	CONDORNEWS: "categories_condornews",
	TRAINING: "categories_training",
};

export const VIDEO_COLLECTION_NAME = {
	CONDORNEWS: "condor_news_videos",
	TRAINING: "training_videos",
};

export const DEACTIVATE_PLAYER_PASSWORD_TIMEOUT = 10000;
