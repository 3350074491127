import { QueryClient } from "@tanstack/react-query";
import {
	IS_DEV_DEBUG,
	REACT_QUERY_CACHE_TIME,
	REACT_QUERY_STALE_TIME,
} from "../../utils/constants";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import type React from "react";

const queryClient = new QueryClient({
	defaultOptions: {
		mutations: {},
		queries: {
			staleTime: REACT_QUERY_STALE_TIME,
			cacheTime: REACT_QUERY_CACHE_TIME,
			networkMode: "offlineFirst",
			// retry: false,
		},
	},
});

const localStoragePersistor = createSyncStoragePersister({
	storage: window.localStorage,
});

export const ProvideReactQuery = ({
	children,
}: { children: React.ReactNode }) => {
	return (
		<PersistQueryClientProvider
			client={queryClient}
			persistOptions={{
				persister: localStoragePersistor,
				maxAge: REACT_QUERY_CACHE_TIME,
				buster: "",
			}}
		>
			{children}
			{IS_DEV_DEBUG && <ReactQueryDevtools initialIsOpen />}
		</PersistQueryClientProvider>
	);
};
