import type React from "react";
import { useMemo, useState } from "react";
import { useVideoPlayerStore } from "../../utils/store";
import { useNavigate, useParams } from "react-router-dom";
import { BreadCrumb } from "./BreadCrumb";
import { Stack } from "@mui/material";
import { SearchInput } from "./SearchInput";
import { VideosButtons } from "./VideosButtons";
import { LoadingVideos } from "./LoadingVideos";
import { BigButtons } from "./BigButtons";
import justPoly from "../../assets/buttons/just-poly.svg";
import { CenterBigButtons } from "../SubPageLayout";
import { usePlayerData } from "../usePlayerData";
import type { ISubCategory } from "../../entities/categorie.entity";
import _ from "lodash";
import { ContentContainer } from "./ContentContainer";
import { filterText } from "../../dashboard/utils";

export const SubSelectorVideos = (props: {
	subcategories: ISubCategory[];
	breadcrumb: string[];
}) => {
	const navigate = useNavigate();
	return (
		<div className="h-[70vh]">
			<CenterBigButtons>
				<BigButtons
					buttons={props.subcategories.map((subcategory) => {
						const [sub] = subcategory.name.split("/");
						return {
							label: sub,
							content: (
								<button
									onClick={() =>
										navigate(
											{
												pathname: `${subcategory.id}`,
											},
											{
												relative: "path",
											},
										)
									}
									className="w-full flex items-center text-white justify-center h-40 bg-contain bg-center bg-no-repeat uppercase font-bold text-xl p-24"
									style={{
										backgroundImage: `url(${justPoly})`,
									}}
								>
									<span>{sub}</span>
								</button>
							),
						};
					})}
				/>
			</CenterBigButtons>
		</div>
	);
};

export const VideosFiltered = (props: {
	groupName: string;
	filter: string;
}) => {
	const playerData = usePlayerData();

	const group = playerData.query.data?.groups.find(
		(group) => group.name === props.groupName,
	);
	const filteredList = useMemo(() => {
		if (!group) {
			return [];
		}

		return (
			group?.videos?.filter((video) => {
				return filterText(video.title, props.filter);
			}) || []
		);
	}, [group, props.filter]);

	if (playerData.query.isLoading) {
		return <LoadingVideos breadcrumb={[]} />;
	}

	if (playerData.query.isError) {
		return <div>Erro ao carregar dados do player</div>;
	}

	const isEmpty = filteredList.length === 0;

	if (isEmpty) {
		return (
			<div className="mt-6 px-4 text-white w-full">
				<div className="bg-black bg-opacity-50 rounded-lg p-4 w-full">
					<h2 className="uppercase text-xl md:text-2xl pb-1 font-semibold">
						{`Sem resultados para "${props.filter}"`}
					</h2>
				</div>
			</div>
		);
	}

	return (
		<div className="mt-6 px-4 text-white">
			<VideosButtons videos={filteredList} />
		</div>
	);
};

export const useVideoOpen = () => {
	const videoPlayer = useVideoPlayerStore();
	const handleVideoClick = async (videoUrl: string) => {
		videoPlayer.setLoop(false);
		await videoPlayer.playVideo({
			originalUrl: videoUrl,
		});
	};

	return handleVideoClick;
};

export const VideosListHeader = (props: {
	onChange: (value: string) => void;
	searchValue?: string;
	breadcrumb: string[];
}) => {
	return (
		<Stack direction={"row"} alignItems={"center"}>
			<BreadCrumb steps={props.breadcrumb} />
			<SearchInput onChange={props.onChange} value={props.searchValue} />
		</Stack>
	);
};

export const ErrorMessage = ({ message }: { message: string }) => {
	return (
		<div className="bg-red-800 text-white bg-opacity-50 rounded-lg p-4 w-full">
			<h2 className="uppercase text-xl md:text-2xl pb-1 font-semibold">Erro</h2>
			<p>{message}</p>
		</div>
	);
};

export const CategoryVideos = (props: {
	breadcrumb: string[];
	categoryId?: string;
	groupName: string;
	suffixButton?: React.ReactNode;
}) => {
	const playerData = usePlayerData();
	const navigate = useNavigate();
	const params = useParams();

	const groupName = props.groupName;

	if (playerData.query.isLoading) {
		return <LoadingVideos breadcrumb={props.breadcrumb} />;
	}

	if (playerData.query.isError) {
		return <div>Erro ao carregar dados do player</div>;
	}

	const activeGroup = playerData.query.data.groups.find(
		(group) => group.name === groupName,
	);

	if (!activeGroup) {
		return <ErrorMessage message={`Grupo não encontrado: ${groupName}`} />;
	}

	if (!props.categoryId) {
		return (
			<ErrorMessage
				message={`Category ID não informado: ${props.categoryId}`}
			/>
		);
	}

	const activeCategory = _.find(activeGroup.categories, {
		id: props.categoryId,
	});

	if (!activeCategory) {
		return (
			<ErrorMessage message={`Categoria não encontrada: ${props.categoryId}`} />
		);
	}

	const hasSub2x = activeCategory.subCategories.every(
		(sub) => sub.subCategories && sub.subCategories.length > 0,
	);

	if (hasSub2x && !params.subcategory) {
		return (
			<CenterBigButtons>
				<BigButtons
					buttons={activeCategory.subCategories.map((subCategory) => {
						return {
							label: subCategory.name,
							onClick: () =>
								navigate({
									pathname: subCategory.id,
								}),
							content: (
								<div className="relative">
									<img
										src={justPoly}
										alt={subCategory.name}
										className="w-full"
									/>
									<div className="absolute inset-0 flex items-center justify-center">
										<span className="max-w-[66%] text-center font-bold uppercase">
											{subCategory.name}
										</span>
									</div>
								</div>
							),
						};
					})}
					suffix={props.suffixButton}
				/>
			</CenterBigButtons>
		);
	}

	return (
		<div className="w-full mx-auto space-y-3 text-white">
			{activeCategory.subCategories
				.filter((subCategory) => {
					if (!params.subcategory) {
						return true;
					}

					return subCategory.id === params.subcategory;
				})
				.map((subCategories) => {
					const hasSubSubCategories =
						subCategories.subCategories &&
						subCategories.subCategories.length > 1;

					if (hasSubSubCategories) {
						return subCategories.subCategories!.map((sub) => (
							<div key={sub.id}>
								<h2 className="uppercase text-xl md:text-2xl pb-1 font-semibold text-condor-blue">
									{sub.name}
								</h2>
								<VideosButtons videos={sub.videos} />
							</div>
						));
					}

					return (
						<div key={subCategories.id}>
							<VideosButtons videos={subCategories.videos} />
						</div>
					);
				})}
		</div>
	);
};

export const FilterableListController = (props: {
	children?: React.ReactNode;
	groupName: string;
	breadcrumb: string[];
}) => {
	const [filter, setFilter] = useState("");
	const isFiltering = filter.length > 0;

	return (
		<ContentContainer>
			<VideosListHeader
				key="same-header"
				onChange={setFilter}
				breadcrumb={props.breadcrumb}
				searchValue={filter}
			/>
			{isFiltering || !props.children ? (
				<VideosFiltered groupName={props.groupName} filter={filter} />
			) : (
				props.children
			)}
		</ContentContainer>
	);
};
