import type { IVideo } from "../../entities/video.entity";
import { playerActivationStore } from "../../utils/firebase";
import { useAnalytics } from "../hooks/useAnalytics";
import { useVideoOpen } from "./VideosList";

export const formatDuration = (duration: IVideo["duration"]) => {
	const hoursQty =
		duration.minutes > 60 ? Math.floor(duration.minutes / 60) : 0;
	const minutesQty =
		duration.minutes > 60 ? duration.minutes % 60 : duration.minutes;
	const hours = hoursQty.toString().padStart(2, "0");
	const minutes = minutesQty.toString().padStart(2, "0");
	const seconds = duration.seconds.toString().padStart(2, "0");
	if (hoursQty > 0) {
		return `${hours}h${minutes}m${seconds}s`;
	}

	return `${minutes}m${seconds}s`;
};

export function VideosButtons(props: {
	videos: IVideo[];
}) {
	const analytics = useAnalytics();
	const handleVideoClick = useVideoOpen();
	return (
		<div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-1">
			{props.videos.map((ch, index) => (
				<button
					key={`${ch.title} ${ch.id} ${index}`}
					onClick={() => {
						handleVideoClick(ch.video.url);
						analytics.video({
							playerId: playerActivationStore.activationPassword!,
							videoId: ch.id,
							videoName: ch.title,
						});
					}}
					data-name={ch.title}
					className="flex space-x-4 text-left transition-all rounded-xl p-2
        bg-condor-blue focus:ring-3 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-condor-red
        border-4
        border-transparent
        hover:border-condor-red
        items-center
      "
				>
					<img
						className="w-[15vw] aspect-video rounded-lg"
						src={ch.thumbnail.url}
						alt={ch.title}
					/>
					<div className="flex flex-col h-full">
						<h3 className="text-md font-bold">{ch.title}</h3>
						<p className="opacity-70 text-xs z-0 w-full mt-1">
							{ch.description}
						</p>
						<span className="text-xs mt-1">
							Duração: {formatDuration(ch.duration)}
						</span>
					</div>
				</button>
			))}
		</div>
	);
}
