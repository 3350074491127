import { Navigate, Outlet, type RouteObject } from "react-router-dom";
import { FirebaseAuthProvider } from "./firebase";
import LoginPage from "./pages/LoginPage";
import { ProtectedRoute } from "./ProtectedRoute";
import Categories from "./pages/Categories";
import Embed from "./pages/Embed";
import Players from "./pages/Players";
import { ProvideReactQuery } from "./config/reactQuery";
import Videos from "./pages/Videos";
import Groups from "./pages/Groups";
import { ThemeProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { baselightTheme } from "../config/theme";
import { RootLayout } from "./layout";
import Users from "./pages/Users";
import Home from "./pages/Home";

export const routes: RouteObject = {
	path: "/dashboard",
	element: (
		<ProvideReactQuery>
			<ThemeProvider theme={baselightTheme}>
				<CssBaseline />
				<FirebaseAuthProvider>
					<Outlet />
				</FirebaseAuthProvider>
			</ThemeProvider>
		</ProvideReactQuery>
	),
	children: [
		{
			path: "login",
			element: <LoginPage />,
		},
		{
			path: "",
			element: (
				<RootLayout>
					<ProtectedRoute>
						<Outlet />
					</ProtectedRoute>
				</RootLayout>
			),
			children: [
				{
					path: "",
					element: <Navigate to="/dashboard/categories" />,
				},
				{
					path: "home",
					element: <Home />,
				},
				{
					path: "embed",
					element: <Embed />,
				},
				{
					path: "players",
					element: <Players />,
				},
				{
					path: "categories",
					element: <Categories />,
				},
				{
					path: "groups",
					element: <Groups />,
				},
				{
					path: "videos",
					element: <Videos />,
				},
				{
					path: "users",
					element: <Users />,
				},
			],
		},
	],
};
