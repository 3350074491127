import { useNavigate } from "react-router-dom";
import { CenterBigButtons, PageContainter } from "../SubPageLayout";
import { BigButtons } from "./BigButtons";
import { openUrl } from "./openUrl";

export function BigButtonsPage(props: {
	buttons: {
		label: string;
		backgroundUrl: string;
		path?: string;
		href?: string;
		target?: string;
	}[];
	prefix?: React.ReactNode;
	suffix?: React.ReactNode;
}) {
	const navigate = useNavigate();

	return (
		<>
			<PageContainter>
				<CenterBigButtons>
					{props.prefix}
					<div className="mt-[2h]">
						<BigButtons
							buttons={props.buttons.map((button) => {
								return {
									label: button.label,
									backgroundUrl: button.backgroundUrl,
									onClick: () => {
										if (button.path) return navigate(button.path);
										if (button.href) return openUrl(button.href);
									},
								};
							})}
						/>
					</div>
					{props.suffix}
				</CenterBigButtons>
			</PageContainter>
		</>
	);
}
