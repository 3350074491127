import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
	TextareaAutosize,
	Box,
} from "@mui/material";
import {
	useState,
	useCallback,
	type FormEvent,
	type ChangeEvent,
	useEffect,
} from "react";
import type { IEmbed } from "../../entities/embed.entity";

interface EmbedDetailsProps {
	item?: IEmbed;
	handleSubmitEmbed: (event: FormEvent<HTMLFormElement>, data: IEmbed) => void;
}

export default function EmbedDetails(props: EmbedDetailsProps) {
	const { item, handleSubmitEmbed } = props;
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		setIsFocused(false);
	};

	const [values, setValues] = useState<IEmbed>({
		id: "",
		name: "",
		description: "",
		createdAt: new Date(),
		html: "",
	});

	useEffect(() => {
		if (item?.id) {
			setValues(item);
		}
	}, [item]);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setValues((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}, []);

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Embed</Typography>
			</div>

			<form
				autoComplete="off"
				noValidate
				onSubmit={(e) => handleSubmitEmbed(e, values)}
			>
				<Grid container spacing={3}>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							helperText="Digite o nome do embed"
							label="Nome"
							name="name"
							onChange={handleChange}
							value={values.name}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							helperText="Digite a descrição"
							label="Descrição"
							name="description"
							onChange={handleChange}
							value={values.description}
						/>
					</Grid>
					<Grid xs={12}>
						<Stack spacing={2}>
							<Typography variant="h6">HTML:</Typography>
							<Box
								sx={{
									width: "100%",
									outline: isFocused
										? "2px solid rgb(59 130 246 / 1)"
										: "1px solid lightGrey",
									padding: 1,
								}}
							>
								<TextareaAutosize
									aria-label="texto em html"
									placeholder="Digite seu HTML aqui"
									minRows={5}
									maxRows={10}
									style={{ width: "100%", outline: "none" }}
									name="html"
									value={values.html}
									onChange={handleChange}
									onFocus={handleFocus}
									onBlur={handleBlur}
								/>
							</Box>
							{values.html && (
								<Stack>
									<Typography
										sx={{ fontSize: "0.75rem", color: "rgba(0, 0, 0, 0.6)" }}
									>
										Pré-visualização:
									</Typography>
									<Box
										sx={{
											border: "solid 1px lightGrey",
											borderRadius: 1,
											padding: 1,
										}}
										// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
										dangerouslySetInnerHTML={{ __html: values.html }}
									/>
								</Stack>
							)}
						</Stack>
					</Grid>

					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
						>
							SALVAR
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
