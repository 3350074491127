import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { PlayerDeactivate } from "./PlayerAuth";
import { DEACTIVATE_PLAYER_PASSWORD_TIMEOUT } from "../utils/constants";

export const PlayerAuthRoute = ({
	children,
}: { children: React.ReactNode }) => {
	const isActivated = localStorage.getItem("activated");
	const [isPasswordRequested, setIsPasswordRequested] = useState(false);

	useEffect(() => {
		if (!window.api?.isInstalledPlayer) {
			return;
		}

		window.api?.onRequestPassword(() => {
			if (isPasswordRequested) {
				return;
			}

			setIsPasswordRequested(true);

			setTimeout(() => {
				setIsPasswordRequested(false);
			}, DEACTIVATE_PLAYER_PASSWORD_TIMEOUT);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (isPasswordRequested) {
		return <PlayerDeactivate />;
	}

	if (isActivated) {
		return children;
	}

	return <Navigate to="/" />;
};
