import { Stack, Typography, Button, SvgIcon } from "@mui/material";
import { useRef, useEffect } from "react";
import ArrowUpTrayIcon from "@heroicons/react/24/solid/ArrowUpTrayIcon";
import type { IDuration } from "../../entities/video.entity";
import { VisuallyHiddenInput } from "./VisuallyHiddenInput";

type VideoInputProps = {
	value?: string;
	onDuration?: (duration: IDuration) => void;
	handleVideoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function VideoInput(props: VideoInputProps) {
	const videoEl = useRef<HTMLVideoElement>(null);

	useEffect(() => {
		videoEl.current?.addEventListener("loadedmetadata", () => {
			const videoDuration = videoEl.current!.duration;

			// TODO: move this to a library maybe
			const minutes = Math.floor(videoDuration / 60);
			const seconds = Math.floor(videoDuration % 60);

			props.onDuration?.({
				minutes,
				seconds,
			});
		});

		const video = videoEl.current;

		return () => {
			video?.removeEventListener("loadedmetadata", () => {});
		};
	}, [props.onDuration, videoEl]);

	return (
		<Stack spacing={2}>
			{props.value && (
				<>
					<Typography>Preview vídeo</Typography>
					<video
						src={props.value}
						width="100%"
						controls
						ref={videoEl}
						style={{
							display: props.value ? "block" : "none",
						}}
					>
						<source type="video/mp4" />
						<p>Seu navegador não suporta o elemento de vídeo.</p>
					</video>
				</>
			)}
			<Button
				component="label"
				variant="outlined"
				startIcon={
					<SvgIcon fontSize="small">
						<ArrowUpTrayIcon />
					</SvgIcon>
				}
				href="#video-upload"
			>
				CARREGAR VÍDEO
				<VisuallyHiddenInput
					id="video-upload"
					type="file"
					accept="video/mp4"
					onChange={props.handleVideoUpload}
				/>
			</Button>
		</Stack>
	);
}
