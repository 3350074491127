import { create } from "zustand";
import { getVideoUrl, migrateVideoUrl } from "./videoCache";
import type { Video } from "../main";
export const useVideoPlayerStore = create<{
	videos: Video[];
	isSplash: boolean;
	playingSplashSince: number;
	isPlaying: boolean;
	isLoop: boolean;
	playVideos: (videos: Video[], isSplash?: boolean) => Promise<void>;
	playVideo: (video: Video, isSplash?: boolean) => Promise<void>;
	playSplash: () => void;
	splashs: Video[];
	setSplashs: (splashVideos: Video[]) => void;
	open: () => void;
	setLoop: (loop: boolean) => void;
	videoFinished: () => void;
	stop: () => void;
}>((set, get) => ({
	isSplash: false,
	playingSplashSince: 0,
	videos: [],
	splashs: [],
	isPlaying: false,
	isLoop: false,
	setLoop(loop: boolean) {
		set(() => {
			return {
				isLoop: loop,
			};
		});
	},
	setSplashs(splashVideos: Video[]) {
		set(() => {
			return {
				splashs: splashVideos,
			};
		});
	},
	async playSplash() {
		await get().playVideos(get().splashs, true);
		set(() => {
			return {
				playingSplashSince: Date.now(),
			};
		});
	},
	async playVideos(videos: Video[], isSplash = false) {
		for (const video of videos) {
			get().playVideo(video, isSplash);
		}

		get().setLoop(isSplash);
		set(() => {
			return {
				isSplash,
			};
		});
	},
	videoFinished() {
		set((state) => {
			if (state.videos.length === 0) {
				get().stop();
				return {};
			}

			const currentVideo = state.videos.shift();
			if (state.isLoop) {
				state.videos.push(currentVideo!);
			}
			return {
				videos: state.videos,
				isPlaying: state.videos.length > 0,
			};
		});
	},
	stop() {
		if (this.isSplash) {
			const splashDuration = Date.now() - this.playingSplashSince;
			if (splashDuration < 1000) {
				return;
			}
		}

		set(() => {
			return {
				isPlaying: false,
				isSplash: false,
				videos: [],
			};
		});
	},
	async playVideo(newVideo: Video, isSplash = false) {
		const migratedOriginalUrl = migrateVideoUrl(newVideo.originalUrl);

		set((state) => {
			return {
				isPlaying: true,
				isSplash,
				videos: [
					...state.videos,
					{
						originalUrl: migratedOriginalUrl,
					},
				],
			};
		});

		const videoUrl = await getVideoUrl(
			migratedOriginalUrl,
			newVideo.originalUrl,
		);

		set((state) => {
			return {
				videos: state.videos.map((video) => {
					if (video.originalUrl === migratedOriginalUrl) {
						return {
							...video,
							url: videoUrl,
						};
					}
					return video;
				}),
			};
		});
	},
	open() {
		set(() => {
			return {
				isPlaying: true,
			};
		});
	},
}));
