import type { ImgHTMLAttributes } from "react";
import logoLogo from "../../assets/tv_zonta_logo_tv_zonta.png";
import logoWhiteLogo from "../../assets/tv_zonta_logo_menu.svg";

function Logo({
	white,
	...props
}: ImgHTMLAttributes<HTMLImageElement> & { white?: boolean }) {
	if (white) {
		return <img {...props} src={logoWhiteLogo} alt="CondorTV" />;
	}

	return <img {...props} src={logoLogo} alt="CondorTV" />;
}

export default Logo;
