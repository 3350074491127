import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
	styled,
	SvgIcon,
	IconButton,
	InputAdornment,
	Chip,
} from "@mui/material";
import { useState, useCallback, type FormEvent, type ChangeEvent } from "react";
import ArrowUpTrayIcon from "@heroicons/react/24/solid/ArrowUpTrayIcon";
import type {
	ICategory,
	ISubCategory,
} from "../../player/entities/categorie.entity";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { useMutation } from "@tanstack/react-query";
import { useCategoriesList } from "../hook/useCategoriesList";
import {
	createCategory,
	firebaseAddStorage,
	updateCategory,
} from "../../utils/firebase";
import { enqueueSnackbar } from "notistack";
import { captureException } from "@sentry/react";

interface CategorieProps {
	item?: ICategory;
	onCategorySaved: () => void;
}

export default function CategorieDetails(props: CategorieProps) {
	const { item, onCategorySaved } = props;
	const categoriesQuery = useCategoriesList();

	const saveCategoryMutation = useMutation({
		mutationKey: ["updateCategory"],
		async mutationFn(data: ICategory) {
			if (data.bgImage.file) {
				const response = await firebaseAddStorage(
					"categories",
					data.bgImage.file,
				);

				data.bgImage = response;
				// biome-ignore lint/performance/noDelete: <explanation>
				delete data.bgImage.file;
			}

			if (data.id) {
				await updateCategory(data);
			} else {
				await createCategory(data);
			}
		},
		onSuccess() {
			onCategorySaved();
			categoriesQuery.refetch();
			enqueueSnackbar({
				variant: "success",
				message: "Categoria salva com sucesso",
			});
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao salvar categoria",
			});
		},
	});

	const handleSubmitCategorie = useCallback(
		(event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();
			saveCategoryMutation.mutate(values);
		},
		[saveCategoryMutation],
	);

	const [createSubCategorie, setCreateSubCategorie] = useState<ISubCategory>({
		id: "",
		name: "",
	});

	const [values, setValues] = useState<ICategory>(
		item || {
			id: "",
			name: "",
			description: "",
			createdAt: "",
			subCategories: [],
			bgImage: {
				url: "",
				file: "" as unknown as File,
			},
		},
	);

	const setSubCategorie = useCallback((subCategorie: ISubCategory) => {
		setValues((old) => ({
			...old,
			subCategories: old.subCategories.map((subCat) => {
				if (subCat.id === subCategorie.id) {
					return subCategorie;
				}

				return subCat;
			}),
		}));
	}, []);

	const handleAddSubCategorie = useCallback(() => {
		if (!createSubCategorie.name) {
			enqueueSnackbar({
				variant: "error",
				message: "Nome da subcategoria é obrigatório",
			});
			setCreateSubCategorie((old) => ({
				...old,
				error: "Nome da subcategoria é obrigatório",
			}));
			return;
		}

		setValues((old) => ({
			...old,
			subCategories: [...old.subCategories, createSubCategorie],
		}));
		setCreateSubCategorie({
			id: "",
			name: "",
		});
	}, [createSubCategorie]);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		console.log("new file", event.target.files);
		const file = event.target.files?.[0];

		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setValues({ ...values, bgImage: { url: imageUrl, file } });
		}
	};

	const VisuallyHiddenInput = styled("input")`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    white-space: nowrap;
    width: 1px;
  `;

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setValues((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}, []);

	const handleDeleteCategorie = useCallback(
		(id: string) => {
			const aux = values.subCategories.filter((subCat) => subCat.id !== id);

			setValues((old) => ({ ...old, subCategories: aux }));
		},
		[values.subCategories],
	);

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Categoria</Typography>
			</div>

			<form autoComplete="off" noValidate onSubmit={handleSubmitCategorie}>
				<Grid container spacing={3}>
					<Grid xs={12} md={12}>
						<TextField
							fullWidth
							helperText="Digite o nome da categoria"
							label="Nome"
							name="name"
							onChange={handleChange}
							required
							value={values.name}
						/>
					</Grid>
					<Grid xs={12}>
						<TextField
							fullWidth
							helperText={
								createSubCategorie.error || "Digite o nome de uma subcategoria"
							}
							label="Subcategoria"
							name="subcategory"
							value={createSubCategorie.name}
							error={createSubCategorie.error}
							onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
								setCreateSubCategorie({
									id: Date.now().toString(),
									name: event.target.value,
								})
							}
							required
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="icon plus"
											onClick={handleAddSubCategorie}
										>
											<SvgIcon>
												<PlusIcon />
											</SvgIcon>
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</Grid>
					{values.subCategories.length > 0 && (
						<Grid xs={12}>
							<Typography fontSize={"small"} color={"rgba(0, 0, 0, 0.5)"}>
								Subcategorias
							</Typography>
							<Stack
								direction="row"
								flexWrap={"wrap"}
								gap={1}
								padding={2}
								border={"solid 1px rgba(0, 0, 0, 0.2)"}
								borderRadius={"5px"}
							>
								{values.subCategories.map((subCat) => (
									<Chip
										key={subCat.id}
										label={
											<TextField
												fullWidth
												onChange={(
													event: React.ChangeEvent<HTMLInputElement>,
												) =>
													setSubCategorie({
														id: subCat.id,
														name: event.target.value,
													})
												}
												required
												value={subCat.name}
											/>
										}
										style={{
											height: 72,
										}}
										onDelete={() => handleDeleteCategorie(subCat.id)}
									/>
								))}
							</Stack>
						</Grid>
					)}
					<Grid sx={{ gap: "10px" }} xs={12}>
						<Stack spacing={2}>
							{values.bgImage.url && (
								<img
									style={{ borderRadius: 5 }}
									src={values.bgImage.url}
									alt="Imagem de fundo"
								/>
							)}
							<Button
								component="label"
								variant="outlined"
								startIcon={
									<SvgIcon fontSize="small">
										<ArrowUpTrayIcon />
									</SvgIcon>
								}
								href="#file-upload"
							>
								SUBIR IMAGEM DE FUNDO
								<VisuallyHiddenInput
									id="file-upload"
									type="file"
									accept="image/jpg, image/jpeg, image/png, image/webp, image/gif, image/svg+xml, image/bmp"
									onChange={handleImageUpload}
								/>
							</Button>
						</Stack>
					</Grid>
					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
							disabled={saveCategoryMutation.isLoading}
						>
							{saveCategoryMutation.isLoading ? "Salvando..." : "Salvar"}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
