const BUTTON =
	"flex items-center justify-center space-x-1 w-full hover:scale-110 transition-all hover:brightness-110";

export function BigButton(props: {
	label: string;
	backgroundUrl?: string;
	content?: React.ReactNode;
	onClick?: () => void;
}) {
	return (
		<div key={props.label} className="w-[33%] bg-contain bg-center">
			<button
				onClick={props.onClick}
				className={BUTTON}
				data-name={props.label}
			>
				{props.content || (
					<img className="w-full" src={props.backgroundUrl} alt={props.label} />
				)}
			</button>
		</div>
	);
}
