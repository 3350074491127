export const filterText = (a: string, b: string) => {
	return removeAccents(a.toLowerCase()).includes(
		removeAccents(b.toLowerCase()),
	);
};

export const removeAccents = (str: string) => {
	// biome-ignore lint/suspicious/noMisleadingCharacterClass: <explanation>
	return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
