import {
	FormControl,
	Checkbox,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	type SelectChangeEvent,
} from "@mui/material";
import type { ReactNode } from "react";
import { useCategoriesList } from "../hook/useCategoriesList";
import _ from "lodash";
import type { ISubCategory } from "../../entities/categorie.entity";

const getSubCategoryLabel = (
	subCategory: ISubCategory & { categoryName: string },
) => {
	return `${subCategory.categoryName} -> ${subCategory.name}`;
};

export function SubCategoryInput({
	value = [],
	onChange,
}: {
	value: string[];
	onChange: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
}) {
	const categoriesList = useCategoriesList();

	const subCategories =
		_.chain(categoriesList.data)
			.map((category) => {
				return _.chain(category.subCategories)
					.map((subCategory) => {
						return {
							...subCategory,
							categoryName: category.name,
						};
					})
					.value();
			})
			.flatten()
			.value() || [];

	if (categoriesList.isLoading) return <div>Loading...</div>;

	return (
		<FormControl fullWidth>
			<InputLabel id="subCategory-input-label">Categorias</InputLabel>
			<Select
				labelId="subCategory-input-label"
				multiple
				value={value}
				name="categories"
				required
				onChange={onChange}
				input={<OutlinedInput label="Tag" />}
				renderValue={(selected) => {
					const selectedCategories = selected as string[];
					return selectedCategories
						.map((value) => {
							const subCategory = subCategories.find(
								(category) => category.id === value,
							);

							if (!subCategory)
								throw new Error("Subcategory not found " + value);

							return getSubCategoryLabel(subCategory);
						})
						.join(", ");
				}}
			>
				{subCategories.map((subCategory) => (
					<MenuItem key={subCategory.id} value={subCategory.id}>
						<Checkbox checked={value.includes(subCategory.id!)} />
						<ListItemText primary={getSubCategoryLabel(subCategory)} />
					</MenuItem>
				))}
			</Select>

			{/* <code>{JSON.stringify(categoriesList.data, null, 2)}</code> */}
		</FormControl>
	);
}
