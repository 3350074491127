import { useQuery } from "@tanstack/react-query";
import { lazyCategories } from "../../hooks/useCategories";

export function useCategoriesList() {
	return useQuery({
		queryKey: ["categories", "list"],
		async queryFn() {
			const data = await lazyCategories(true);
			return data.categories;
		},
	});
}
