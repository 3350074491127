import { listEmbeds } from "../../utils/firebase";
import { useQuery } from "@tanstack/react-query";

export const useEmbedByName = (name: string) => {
	return useQuery({
		queryKey: ["embed", name],
		queryFn: async () => {
			const data = await listEmbeds();

			for (const embed of data) {
				if (embed.name == name) return embed;
			}
		},
	});
};
