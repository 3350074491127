import type React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import Logo from "./components/Logo";
import { activatePlayer, playerActivationStore } from "../utils/firebase";
import { useSnackbar } from "notistack";
import { captureException } from "@sentry/react";

export const PlayerAuth = () => {
	const navigate = useNavigate();
	const isActivated = playerActivationStore.isActivated;

	if (isActivated) {
		return <Navigate to="/player" />;
	}

	return (
		<div className="h-screen flex flex-col items-end justify-center">
			<div className="max-w-xl w-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
				<div className="flex justify-center">
					<Logo className="w-[320px]" />
				</div>
				<h3 className="text-lg font-bold text-center text-condor-blue mt-8">
					Entre com sua senha de ativação do player
				</h3>
				<PlayerAuthForm
					onSuccess={() => {
						navigate("/player");
					}}
				/>
			</div>
		</div>
	);
};

export const PlayerDeactivate = () => {
	return (
		<div className="h-screen flex flex-col items-end justify-center">
			<div className="max-w-xl w-full mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
				<div className="flex justify-center">
					<Logo className="w-[320px]" />
				</div>
				<h3 className="text-lg font-bold text-center text-white mt-8">
					Entre com sua senha para desativar o player
				</h3>
				<PlayerAuthForm
					onSuccess={() => {
						localStorage.removeItem("activated");
						window.api?.passwordAccepted();
					}}
					deactivate
				/>
			</div>
		</div>
	);
};

export const PlayerAuthForm = ({
	onSuccess,
	onError,
	deactivate,
}: {
	onSuccess: () => void;
	onError?: (error: Error) => void;
	deactivate?: boolean;
}) => {
	const { enqueueSnackbar } = useSnackbar();

	const activatePlayerMutation = useMutation({
		mutationKey: ["activatePlayer"],
		mutationFn: async (password: string) => {
			console.log("will activate");
			await activatePlayer(password);
			console.log("activated");
		},
		onError: (error: Error) => {
			alert("error activating player");
			captureException(error);
			enqueueSnackbar({
				message:
					"Ocorreu um problema ao tentar ativar o player. Se estiver ativando pela primeira vez, verifique a conexão e tente novamente.",
				variant: "error",
			});
			onError?.(error);
		},
		onSuccess: () => {
			enqueueSnackbar({
				message: "Player ativado com sucesso.",
				variant: "success",
			});
			onSuccess();
		},
	});

	const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		console.log("form submited");
		const formData = new FormData(event.currentTarget);
		const password = formData.get("password") as string;
		activatePlayerMutation.mutate(password);
	};

	return (
		<form
			className="flex flex-col shadow-md border-condor-red border-2 rounded mt-4"
			onSubmit={handleFormSubmit}
		>
			<input
				type="password"
				placeholder="* * * * *"
				className="rounded-t p-2 text-lg text-center"
				name="password"
			/>
			{activatePlayerMutation.isLoading ? (
				<div className="flex justify-center items-center h-12 bg-gray-600 text-white font-bold uppercase">
					Ativando...
				</div>
			) : (
				<button
					className="g-recaptcha bg-condor-red text-white font-bold uppercase rounded-b p-2"
					data-sitekey="6LfFwisoAAAAAO4U7wOcckA1hzIoqjUvJBP60LaH"
					data-callback="onSubmit"
					data-action="submit"
				>
					{deactivate ? "DESATIVAR PLAYER" : "ATIVAR PLAYER"}
				</button>
			)}
		</form>
	);
};
