import { useMutation, useQuery } from "@tanstack/react-query";
import {
	createPresignedUrl,
	createVideo,
	queryVideos,
	updateVideoById,
} from "../../utils/firebase";
import type { ISplashVideo, IVideo } from "../../entities/video.entity";
import { useMemo, useState } from "react";
import { applyPagination } from "../../utils/applyPagination";
import { enqueueSnackbar } from "notistack";
import { useVideoRemover } from "./useVideoRemover";
import { captureException } from "@sentry/react";
import { filterText } from "../utils";
export const isIVideo = (data: IVideo | ISplashVideo): data is IVideo => {
	return data && typeof data === "object" && "thumbnail" in data;
};

const submitVideoMutation = async (data: IVideo | ISplashVideo) => {
	if (data.video.file) {
		const videoPresigned = await createPresignedUrl(data.video.file!, "video");

		await fetch(videoPresigned.signedUpload.url, {
			method: videoPresigned.signedUpload.method,
			body: data.video.file,
			headers: videoPresigned.signedUpload.headers,
		});

		data.video.url = videoPresigned.signedDownload.url;
		delete data.video.file;
	}

	if (isIVideo(data) && data.thumbnail.file) {
		const thumbnailPresigned = await createPresignedUrl(
			data.thumbnail.file,
			"thumbnail",
		);

		await fetch(thumbnailPresigned.signedUpload.url, {
			method: thumbnailPresigned.signedUpload.method,
			body: data.thumbnail.file,
			headers: thumbnailPresigned.signedUpload.headers,
		});

		data.thumbnail.url = thumbnailPresigned.signedDownload.url;
		delete data.thumbnail.file;
	}

	if (data.id) {
		return updateVideoById(data);
	}

	return createVideo(data);
};

const useVideos = (
	page: number,
	rowsPerPage: number,
	data: IVideo[],
	filter?: string,
) => {
	return useMemo(() => {
		let aux = data;

		if (filter) {
			aux = data.filter((item: IVideo) =>
				// item.title?.toLowerCase().includes(filter.toLowerCase()),
				filterText(item.title, filter),
			);
		}

		return {
			items: aux.length,
			pagination: applyPagination(aux, page, rowsPerPage),
		};
	}, [page, rowsPerPage, data, filter]);
};

export const useVideoManager = ({
	onCreated,
	filter,
}: {
	onCreated: () => void;
	filter?: string;
}) => {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	const videosQuery = useQuery({
		queryKey: ["videos", "list"],
		async queryFn() {
			return queryVideos();
		},
		initialData: [],
	});

	const videoSubmit = useMutation({
		mutationKey: ["videos", "create", "submit"],
		mutationFn: submitVideoMutation,
		onSuccess: () => {
			videosQuery.refetch();
			onCreated();
			enqueueSnackbar({
				message: "Video salvo com sucesso",
				variant: "success",
			});
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				message: "Erro ao salvar video. Por favor, tente novamente",
				variant: "error",
			});
		},
	});

	const videoRemover = useVideoRemover({
		onSuccess: () => {
			videosQuery.refetch();
			enqueueSnackbar({
				message: "Video removido com sucesso",
				variant: "success",
			});
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				message: "Erro ao remover video. Por favor, tente novamente",
				variant: "error",
			});
		},
	});

	const videoPaginated = useVideos(page, rowsPerPage, videosQuery.data, filter);

	const getById = (id: string): IVideo | undefined => {
		return videosQuery.data.find((video) => video.id === id);
	};

	return {
		videosQuery,
		videoSubmit,
		videoRemover,
		videoPaginated: videoPaginated.pagination,
		setPage,
		page,
		rowsPerPage,
		setRowsPerPage,
		getById,
		count: videoPaginated.items,
	};
};
