import {
	FormControl,
	Checkbox,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	type SelectChangeEvent,
} from "@mui/material";
import type { ReactNode } from "react";
import { useGroupsList } from "../hook/useGroupsList";

export function GroupInput({
	value = [],
	onChange,
}: {
	value: string[];
	onChange: (event: SelectChangeEvent<string[]>, child: ReactNode) => void;
}) {
	const groupsList = useGroupsList();
	return (
		<FormControl fullWidth>
			<InputLabel id="group-input-label">Grupos</InputLabel>
			<Select
				labelId="group-input-label"
				multiple
				value={value}
				name="groups"
				required
				onChange={onChange}
				input={<OutlinedInput label="Tag" />}
				renderValue={(selected) => {
					const selectedGroups = selected as string[];
					return selectedGroups
						.map((value) => {
							const group = groupsList.data?.find(
								(group) => group.id === value || group.name === value,
							);
							return group?.name;
						})
						.join(", ");
				}}
			>
				{groupsList.data?.map((group) => (
					<MenuItem key={group.id} value={group.id}>
						<Checkbox checked={value.includes(group.id)} />
						<ListItemText primary={group.name} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
