import { captureException } from "@sentry/react";

declare global {
	interface HTMLElement {
		requestFullscreen?: () => void;
		webkitRequestFullscreen?: () => void;
		msRequestFullscreen?: () => void;
	}

	interface Document {
		fullscreenElement?: Element;
		webkitFullscreenElement?: Element;
		msFullscreenElement?: Element;
	}

	interface Window {
		getVideoFromDb: (url: string) => Promise<any>;
	}
}

export async function makeFullscreen(
	elem: HTMLElement,
	onNotSupported?: () => void,
) {
	try {
		if (elem.requestFullscreen) {
			await elem.requestFullscreen();
		}
		if (elem.webkitRequestFullscreen) {
			/* Safari */
			await elem.webkitRequestFullscreen();
		}
		if (elem.msRequestFullscreen) {
			/* IE11 */
			await elem.msRequestFullscreen();
		}
	} catch (error) {
		captureException(error);
		// silence is golden
	}

	const isFullscreen =
		document.fullscreenElement ||
		document.webkitFullscreenElement ||
		document.msFullscreenElement;

	if (isFullscreen) {
		return;
	}

	if (onNotSupported) {
		return onNotSupported();
	}

	throw new Error("Fullscreen not supported");
}
