import { PageContainer } from "./LoginPage";
import {
	Card,
	CardContent,
	Typography,
	Stack,
	Box,
	type TypographyProps,
} from "@mui/material";
import homeDocsMd from "./home-docs.md?raw";
import { getOverrides, MuiMarkdown } from "mui-markdown";
import { baselightTheme } from "../../config/theme";

type Props = {
	title?: string;
	subtitle?: string;
	action?: JSX.Element | any;
	footer?: JSX.Element;
	cardheading?: string | JSX.Element;
	headtitle?: string | JSX.Element;
	headsubtitle?: string | JSX.Element;
	children?: JSX.Element;
	middlecontent?: string | JSX.Element;
};

export const DashboardCard = ({
	title,
	subtitle,
	children,
	action,
	footer,
	cardheading,
	headtitle,
	headsubtitle,
	middlecontent,
}: Props) => {
	return (
		<>
			<Card sx={{ padding: 0 }} elevation={9} variant={undefined}>
				{cardheading ? (
					<CardContent>
						<Typography variant="h5">{headtitle}</Typography>
						<Typography variant="subtitle2" color="textSecondary">
							{headsubtitle}
						</Typography>
					</CardContent>
				) : (
					<CardContent sx={{ px: "20px", py: "30px" }}>
						{title ? (
							<Stack
								direction="row"
								spacing={2}
								justifyContent="space-between"
								alignItems={"center"}
							>
								<Box>
									{title ? <Typography variant="h5">{title}</Typography> : ""}

									{subtitle ? (
										<Typography variant="subtitle2" color="textSecondary">
											{subtitle}
										</Typography>
									) : (
										""
									)}
								</Box>
								{action}
							</Stack>
						) : null}
					</CardContent>
				)}
			</Card>

			<Card sx={{ padding: 0, mt: 1 }} elevation={9} variant={undefined}>
				{children}
				{middlecontent}
				{footer}
			</Card>
		</>
	);
};

const TITLE = "Home";

const Home = () => {
	return (
		<PageContainer title={TITLE}>
			<CardContent>
				<MuiMarkdown
					overrides={{
						...getOverrides(), // This will keep the other default overrides.
						h4: {
							component: Typography,
							props: {
								gutterBottom: true,
								variant: "h4",
								sx: { mt: 2 },
								fontSize: "1.1rem",
							} as TypographyProps,
						},
						ul: {
							component: (props) => (
								<Box
									component="ul"
									{...props}
									sx={{ pl: 2, py: 1, listStyle: "disc" }}
								/>
							),
						},
						ol: {
							component: (props) => (
								<Box
									component="ol"
									{...props}
									sx={{ pl: 2, py: 1, listStyle: "decimal" }}
								/>
							),
						},
					}}
				>
					{homeDocsMd}
				</MuiMarkdown>
			</CardContent>
		</PageContainer>
	);
};

export default Home;
