import type { ReactNode } from "react";

export const ContentContainer = (props: {
	children: ReactNode;
}) => {
	return (
		<div className="w-full mx-auto mt-6 md:mt-16 text-white">
			{props.children}
		</div>
	);
};
