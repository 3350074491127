import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./utils/firebase";
import "./index.css";
import { AnimatePresence } from "framer-motion";

import { registerSW } from "virtual:pwa-register";
import { routes as playerRoutes } from "./player/routes";
import { routes as dashboardRoutes } from "./dashboard/routes";
import { SnackbarProvider } from "notistack";
import * as Sentry from "@sentry/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./utils/firebase";
import { getVideoFromDb } from "./utils/videoCache";
import { migrateData, seed } from "./utils/seedLocal";

export interface Video {
	originalUrl: string;
	url?: string;
}

Sentry.init({
	dsn: "https://a02e8cbbe6e7e3ce40d54bdfaed017b7@o4504192995164160.ingest.sentry.io/4506361614893056",
	integrations: [
		new Sentry.BrowserTracing({
			// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
			// https://condorflix-dev.web.app/
			tracePropagationTargets: [
				"condorflix-dev.web.app",
				/.*\.firebaseapp\.com/,
				/https:\/\/condorflix-dev\.web\.app\/.*/,
				"condor.tvtreinamento.com.br",
			],
		}),
		new Sentry.Replay(),
	],
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const router = createBrowserRouter([playerRoutes, dashboardRoutes]);

if (
	window.api?.isInstalledPlayer ||
	window.location.href.includes("force-install-sw")
) {
	registerSW({ immediate: true });
}

ReactDOM.createRoot(
	document.getElementById("root")! as unknown as DocumentFragment,
).render(
	<React.StrictMode>
		<SnackbarProvider>
			<AnimatePresence mode="wait">
				<RouterProvider router={router} />
			</AnimatePresence>
		</SnackbarProvider>
	</React.StrictMode>,
);

if (import.meta.env.DEV) {
	window.getVideoFromDb = getVideoFromDb;

	window.loginWithEmailAndPassword = async (
		email: string,
		password: string,
	) => {
		return signInWithEmailAndPassword(auth, email, password);
	};

	// @ts-ignore
	window.seed = seed;
	// @ts-ignore
	window.migrateData = migrateData;
	// @ts-ignore
	window.enableDebug = () => {
		localStorage.setItem("debug", "true");
		window.location.reload();
	};
}
