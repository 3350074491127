export const BreadCrumb = ({
	steps,
	H2Props,
}: {
	steps: (string | undefined)[];
	H2Props?: React.HTMLProps<HTMLHeadingElement>;
}) => {
	return (
		<div className="w-full">
			<h2
				{...H2Props}
				className={`text-[2vw] text-condor-blue uppercase ${H2Props?.className}`}
			>
				{steps.filter(Boolean).map((step, index) => {
					const isLastStep = index === steps.length - 1;
					const isFirstStep = index === 0;
					const text = `${isFirstStep ? "" : "/"}${step}`;
					if (isLastStep) {
						return (
							<span key={step} className="font-semibold">
								{text}
							</span>
						);
					}

					return (
						<span key={step} className="font-light mr-1">
							{text}
						</span>
					);
				})}
			</h2>
		</div>
	);
};
