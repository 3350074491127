import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
} from "@mui/material";
import { useState, useCallback, type FormEvent, type ChangeEvent } from "react";
import type { IGroup } from "../../entities/group.entity";

interface CategorieProps {
	item?: IGroup;
	handleSubmitGroup: (event: FormEvent<HTMLFormElement>, data: IGroup) => void;
}

export default function GroupDetails(props: CategorieProps) {
	const { item, handleSubmitGroup } = props;

	const [values, setValues] = useState<IGroup>(
		item || {
			id: "",
			name: "",
			description: "",
			createdAt: "",
		},
	);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setValues((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}, []);

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Grupo</Typography>
			</div>

			<form
				autoComplete="off"
				noValidate
				onSubmit={(e) => handleSubmitGroup(e, values)}
			>
				<Grid container spacing={3}>
					<Grid xs={12} md={12}>
						<TextField
							fullWidth
							helperText="Digite o nome da categoria"
							label="Nome"
							name="name"
							onChange={handleChange}
							required
							value={values.name}
						/>
					</Grid>
					{/* <Grid xs={12} md={6}>
              <TextField
                fullWidth
                helperText="Faça uma descrição"
                label="Descrição"
                name="description"
                onChange={handleChange}
                required
                value={values.description}
              />
            </Grid> */}
					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
						>
							SALVAR
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
