import { useEffect } from "react";
import { FullScreenPlayer } from "./FullScreenPlayer";
import { useVideoPlayerStore } from "../../utils/store";
import { inactiveTimerMeasurer } from "../../utils/inactiveTimerMeasurer";
import { useNavigate } from "react-router-dom";
import { playerActivationStore } from "../../utils/firebase";
import { useQuery } from "@tanstack/react-query";
import { useVideosPreload } from "../hooks/useVideosPreload";
import { useAnalytics } from "../hooks/useAnalytics";
import { ANALYTICS_PING_INTERVAL } from "../../utils/constants";

const STARTED_AT = Date.now();

export const VideoAndTimeoutWrapper = ({
	children,
}: { children: React.ReactNode }) => {
	const videoPlayer = useVideoPlayerStore();
	const videosPreload = useVideosPreload();
	const analytics = useAnalytics();

	useQuery({
		queryKey: ["analytics"],
		queryFn: () => {
			let state: "tocando" | "screensaver" | "parado" = "parado";

			if (videoPlayer.isPlaying) {
				state = videoPlayer.isSplash ? "screensaver" : "tocando";
			}

			return analytics.ping({
				playerId: playerActivationStore.activationPassword!,
				uptime: Date.now() - STARTED_AT,
				state,
				medias_total: videosPreload.total,
				medias: videosPreload.loadedVideos.map((video) => ({
					id: video.id,
					name: video.title,
				})),
			});
		},
		refetchInterval: ANALYTICS_PING_INTERVAL,
		cacheTime: 0,
		staleTime: 0,
		enabled: videosPreload.isFetching || videosPreload.isFetched,
	});

	inactiveTimerMeasurer.setOnInactive(() => {
		if (videoPlayer.isPlaying) {
			// Is playing something, do nothing
			return;
		}

		const isHome = window.location.pathname === "/player";

		if (!isHome) {
			return;
		}

		// Play only if is in home
		videoPlayer.playSplash();
	});

	inactiveTimerMeasurer.setOnActive(() => {
		if (!videoPlayer.isPlaying) {
			return;
		}

		if (videoPlayer.isSplash) {
			videoPlayer.stop();
		}
	});

	useEffect(() => {
		const handleKeyDown = () => {
			inactiveTimerMeasurer.active();
		};
		const handleMouseMove = () => {
			inactiveTimerMeasurer.active();
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<>
			<FullScreenPlayer />
			{children}
			{videosPreload.isFetching && (
				<div className="fixed bottom-0 right-0 left-0 bg-gray-900 text-white text-center p-2">
					{videosPreload.total === 0 ? (
						<CountingBar />
					) : (
						<ProgressBar
							total={videosPreload.total}
							loaded={videosPreload.loaded}
						/>
					)}
				</div>
			)}
		</>
	);
};

function ProgressBar(props: { total: number; loaded: number }) {
	return (
		<div
			className="bg-condor-red text-white p-2 rounded-xl whitespace-nowrap transition-all duration-100"
			style={{ width: `${(props.loaded / props.total) * 100}%` }}
		>
			Preparando vídeos... {props.loaded}/{props.total}
		</div>
	);
}

function CountingBar() {
	return (
		<div className="bg-black/50 text-white p-2 rounded-xl whitespace-nowrap transition-all duration-100">
			Verificando novos vídeos...
		</div>
	);
}
