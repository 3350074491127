import _ from "lodash";
import { lazyCategories } from "../hooks/useCategories";
import { getPlayerByPassword, queryVideos, listGroups } from "./firebase";
import type { IGroup } from "../entities/group.entity";
import type { ISubCategory } from "../entities/categorie.entity";
import type { IVideo } from "../entities/video.entity";
import { LOOP_CATEGORIES_NAME } from "./constants";

const logger = {
	debug: (...args: any[]) => {
		// if (process.env.NODE_ENV === "development") {
		console.log(...args);
		// }
	},
};

export async function fetchAllContent(playerPassword: string) {
	if (!playerPassword) {
		throw new Error("Player password is required");
	}

	const player = await getPlayerByPassword(playerPassword);
	logger.debug("player", player);
	const videos = await queryVideos(player.groups);
	logger.debug("videos", videos);
	const groups = await listGroups();
	logger.debug("groups", groups);
	const { categories } = await lazyCategories();
	logger.debug("categories", categories);

	const isVideoInGroup = (video: IVideo, group: IGroup) => {
		const includeName = video.groups.includes(group.name);
		const includeId = video.groups.includes(group.id);

		if (includeName) {
			return true;
		}

		if (includeId) {
			return true;
		}

		return false;
	};

	const verifyAndCreateSubCategories2xFromSubcategory = (
		subCategories: ISubCategory[],
	) => {
		const reducedSubCategories: ISubCategory[] = [];

		for (const subCategory of subCategories) {
			if (!subCategory.name.includes("/")) {
				reducedSubCategories.push(subCategory);
				continue;
			}

			const [parentName, childName] = subCategory.name.split("/");

			const reducedSubCategory = _.find(reducedSubCategories, {
				name: parentName,
			});

			const subSubCategory = {
				...subCategory,
				id: `${subCategory.id}-child`,
				name: childName,
			};

			if (!reducedSubCategory) {
				reducedSubCategories.push({
					...subCategory,
					name: parentName,
					subCategories: [subSubCategory],
				});
				continue;
			}

			if (reducedSubCategory) {
				if (!reducedSubCategory.subCategories) {
					reducedSubCategory.subCategories = [];
				}

				reducedSubCategory.subCategories.push(subSubCategory);
				continue;
			}
		}

		return reducedSubCategories;
	};

	const content = {
		videos: videos,
		groups: groups.map((group) => {
			const groupVideos = videos.filter((video) =>
				isVideoInGroup(video, group),
			);
			return {
				...group,
				videos: groupVideos,
				categories: categories
					.filter((category) => {
						const subCategoriesIds = _.map(category.subCategories, "id");

						for (const video of groupVideos) {
							if (
								_.intersection(video.subCategoriesIds, subCategoriesIds)
									.length > 0
							) {
								return true;
							}
						}

						return false;
					})
					.map((category) => ({
						...category,
						videos: groupVideos.filter(
							(video) =>
								_.intersection(
									video.subCategoriesIds,
									_.map(category.subCategories, "id"),
								).length > 0,
						),
						subCategories: verifyAndCreateSubCategories2xFromSubcategory(
							category.subCategories
								.map((subCategory) => ({
									...subCategory,
									videos: groupVideos.filter((video) =>
										video.subCategoriesIds.includes(subCategory.id!),
									),
								}))
								.filter((subCategory) => subCategory.videos.length > 0),
						),
					})),
			};
		}),
		splash: videos.filter((video) =>
			video.groups.includes(LOOP_CATEGORIES_NAME),
		),
	} as const;

	logger.debug("content", content);

	return content;
}
