import { useState } from "react";

export const useSidePane = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	const [isOpen, setIsOpen] = useState(false);

	const setOpen = (
		open: boolean,
		event?: React.KeyboardEvent | React.MouseEvent,
	) => {
		if (
			event &&
			event.type === "keydown" &&
			((event as React.KeyboardEvent).key === "Tab" ||
				(event as React.KeyboardEvent).key === "Shift")
		) {
			return;
		}

		if (!open) {
			onClose();
		}

		setIsOpen(open);
	};

	const toggle = () => {
		setOpen(!isOpen);
	};

	return {
		isOpen,
		setOpen,
		toggle,
	};
};
