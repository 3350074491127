import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
	styled,
	SvgIcon,
	CircularProgress,
	type SelectChangeEvent,
} from "@mui/material";
import { useState, useCallback, type FormEvent, type ChangeEvent } from "react";
import ArrowUpTrayIcon from "@heroicons/react/24/solid/ArrowUpTrayIcon";
import type { IVideo } from "../../entities/video.entity";
import { VideoInput } from "./VideoInput";
import VideoSnapshot from "video-snapshot";
import { GroupInput } from "./GroupInput";
import { SubCategoryInput } from "./SubCategoryInput";

interface VideoDetailsProps {
	item?: IVideo;
	isSaving?: boolean;
	onlyVideo?: boolean;
	handleSubmitVideo: (event: FormEvent<HTMLFormElement>, data: IVideo) => void;
}

const VisuallyHiddenInput = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

function dataURLtoFile(dataurl, filename) {
	var arr = dataurl.split(","),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = atob(arr[arr.length - 1]),
		n = bstr.length,
		u8arr = new Uint8Array(n);
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n);
	}
	return new File([u8arr], filename, { type: mime });
}

export default function VideoDetails(props: VideoDetailsProps) {
	const { item, handleSubmitVideo, isSaving } = props;

	const [values, setValues] = useState<IVideo>(
		item || {
			id: "",
			title: "",
			groups: [],
			description: "",
			createdAt: new Date(),
			subCategoriesIds: [],
			duration: {
				minutes: 0,
				seconds: 0,
			},
			thumbnail: {
				url: "",
			},
			video: {
				url: "",
			},
		},
	);

	const handleThumbnailUpload = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];

		if (file) {
			const imageUrl = URL.createObjectURL(file);
			setValues({ ...values, thumbnail: { url: imageUrl, file } });
		}
	};

	const handleVideoUpload = async (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const file = event.target.files?.[0];
		if (!file) throw new Error("File not found");
		const videoUrl = URL.createObjectURL(file);
		const snapshoter = new VideoSnapshot(file);
		console.log("snapshoter", "will take snapshot");
		const previewSrc = await snapshoter.takeSnapshot();
		console.log("snapshoter", "took snapshot");

		setValues((values) => {
			const newState = { ...values, video: { url: videoUrl, file } };

			if (!values.thumbnail.url || !values.thumbnail.file) {
				newState.thumbnail = {
					url: previewSrc,
					file: dataURLtoFile(previewSrc, "thumbnail.png"),
				};
			}
			return newState;
		});
	};

	const handleSelectSubCategory = (event: SelectChangeEvent<string[]>) => {
		setValues((old) => ({
			...old,
			subCategoriesIds: event.target.value as string[],
		}));
	};

	const handleSelectGroup = (event: SelectChangeEvent<string[]>) => {
		setValues((old) => ({
			...old,
			groups: event.target.value as string[],
		}));
	};

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setValues((prevState) => ({
			...prevState,
			[event.target.name]: event.target.value,
		}));
	}, []);

	const isFormInvalid = !checkIsFormValid(values);

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Vídeo</Typography>
			</div>

			<form
				autoComplete="off"
				noValidate
				onSubmit={(e) => handleSubmitVideo(e, values)}
			>
				<Grid container spacing={3}>
					<Grid xs={12} md={6}>
						<SubCategoryInput
							value={values.subCategoriesIds}
							onChange={handleSelectSubCategory}
						/>
					</Grid>

					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							helperText="Digite o nome do vídeo"
							label="Nome"
							name="title"
							onChange={handleChange}
							required
							value={values.title}
						/>
					</Grid>

					<Grid xs={12} md={6}>
						<TextField
							fullWidth
							helperText="Faça uma descrição"
							label="Descrição"
							name="description"
							onChange={handleChange}
							required
							value={values.description}
						/>
					</Grid>

					<Grid xs={12} md={6}>
						<GroupInput onChange={handleSelectGroup} value={values.groups} />
					</Grid>

					{values.subCategoriesIds && (
						<>
							<Grid sx={{ gap: "10px" }} xs={12}>
								<VideoInput
									value={values.video.url}
									handleVideoUpload={handleVideoUpload}
									onDuration={(duration) => {
										console.log("duration success!!", duration);
										setValues((old) => ({ ...old, duration }));
									}}
								/>
							</Grid>
							<Grid sx={{ gap: "10px" }} xs={12}>
								<Stack spacing={2}>
									{values.thumbnail.url && (
										<>
											<Typography>Preview thumbnail</Typography>
											<img
												style={{ borderRadius: 5 }}
												src={values.thumbnail.url}
												alt="Imagem da Thumbnail"
											/>
										</>
									)}
									<Button
										component="label"
										variant="outlined"
										startIcon={
											<SvgIcon fontSize="small">
												<ArrowUpTrayIcon />
											</SvgIcon>
										}
										href="#thumbnail-upload"
									>
										CARREGAR THUMBNAIL
										<VisuallyHiddenInput
											id="thumbnail-upload"
											type="file"
											accept="image/jpg, image/jpeg, image/png, image/webp"
											onChange={handleThumbnailUpload}
										/>
									</Button>
								</Stack>
							</Grid>
						</>
					)}
					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
							endIcon={
								isSaving ? <CircularProgress></CircularProgress> : undefined
							}
							disabled={isFormInvalid || props.isSaving}
						>
							SALVAR
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}

function checkIsFormValid(values: IVideo) {
	const isValid = Boolean(
		values.title &&
			values.description &&
			values.thumbnail.url &&
			values.video.url &&
			typeof values.duration.minutes === "number" &&
			typeof values.duration.seconds === "number" &&
			(values.duration.minutes > 0 || values.duration.seconds > 0),
	);

	// if (!isValid) {
	//   console.log('invalidForm', values)
	// }

	return isValid;
}
