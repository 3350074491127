import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";

export default function DeleteButtonIcon({
	onClick,
	loading,
	disabled,
}: {
	onClick: () => void;
	loading: boolean;
	disabled: boolean;
}) {
	const handleOnClick = () => {
		if (confirm("Tem certeza que deseja deletar?")) {
			onClick();
		}
	};

	if (loading) {
		return (
			<IconButton aria-label="trash" color="error">
				<CircularProgress></CircularProgress>
			</IconButton>
		);
	}

	return (
		<IconButton
			aria-label="trash"
			color="error"
			onClick={handleOnClick}
			disabled={disabled}
		>
			<SvgIcon>
				<TrashIcon />
			</SvgIcon>
		</IconButton>
	);
}
