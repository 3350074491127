import { useParams } from "react-router-dom";
import {
	CategoryVideos,
	FilterableListController,
} from "./components/VideosList";
import { MotionMain } from "./MotionMain";
import { usePlayerData } from "./usePlayerData";
import _ from "lodash";

export const CategoryRender = (props: {
	groupName: string;
	name: string;
	suffixButton?: JSX.Element;
	categoryId?: string;
}) => {
	const params = useParams();
	const playerData = usePlayerData();
	const activeGroup = playerData.query.data?.groups.find(
		(group) => group.name === props.groupName,
	);

	const category = _.find(activeGroup?.categories, {
		id: props.categoryId,
	});

	const subCategory = category?.subCategories.find(
		(sub) => sub.id === params.subcategory,
	);

	const breadcrumb = _.uniq([
		props.name,
		category?.name || "",
		subCategory?.name,
	]).filter(Boolean) as string[];

	return (
		<MotionMain key="/treinamento/category">
			<FilterableListController
				groupName={props.groupName}
				breadcrumb={breadcrumb}
			>
				<div className="pb-14">
					<CategoryVideos
						categoryId={props.categoryId || params.category}
						groupName={props.groupName}
						breadcrumb={breadcrumb}
						suffixButton={props.suffixButton}
					/>
				</div>
			</FilterableListController>
		</MotionMain>
	);
};
