import {
	Stack,
	Typography,
	Unstable_Grid2 as Grid,
	TextField,
	Button,
	Switch,
	FormControlLabel,
} from "@mui/material";
import { useState, useCallback, type FormEvent, type ChangeEvent } from "react";
import type { IUser } from "../../entities/user.entity";
import { useAuthenticatedUser } from "../firebase";
import { enqueueSnackbar } from "notistack";
import { useUserChanger } from "../pages/useUserChanger";

interface UserProps {
	item?: IUser;
	onUserChanged: () => void;
}

export default function UserDetails(props: UserProps) {
	const { item, onUserChanged } = props;
	const authenticatedUser = useAuthenticatedUser();
	const userChanger = useUserChanger({
		onSuccess: onUserChanged,
	});

	const [initialId] = useState(item?.id);

	const [values, setValues] = useState<IUser>(
		item || {
			id: "",
			allowed: true,
		},
	);

	const handleSubmitUser = useCallback(
		(event) => {
			event.preventDefault();

			if (values.id === authenticatedUser.user?.email) {
				enqueueSnackbar({
					variant: "error",
					message: "Você não pode alterar o seu próprio status",
				});
				return;
			}

			userChanger.mutate({ user: values, previousId: initialId });
		},
		[values, authenticatedUser, userChanger],
	);

	const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		let { name, value } = event.target;

		if (name === "allowed") {
			value = value === "true";
		}

		setValues((prevState) => ({
			...prevState,
			[name]: value,
		}));
	}, []);

	return (
		<Stack spacing={3}>
			<div>
				<Typography variant="h4">Usuário</Typography>
			</div>

			<form autoComplete="off" noValidate onSubmit={handleSubmitUser}>
				<Grid container spacing={3}>
					<Grid xs={12} md={12}>
						<TextField
							fullWidth
							helperText="E-mail utilizado para login"
							label="E-mail"
							name="id"
							onChange={handleChange}
							required
							value={values.id}
						/>
					</Grid>
					<Grid xs={12} md={6}>
						<FormControlLabel
							label="Acesso permitido"
							name="allowed"
							control={
								<Switch
									onChange={handleChange}
									name="allowed"
									checked={Boolean(values.allowed)}
									value={!values.allowed}
								/>
							}
						/>
					</Grid>
					<Grid xs={12}>
						<Button
							type="submit"
							sx={{ marginTop: 5 }}
							variant="contained"
							fullWidth
							disabled={userChanger.isLoading}
						>
							{userChanger.isLoading ? "Salvando..." : "Salvar"}
						</Button>
					</Grid>
				</Grid>
			</form>
		</Stack>
	);
}
