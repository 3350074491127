import { useMutation } from "@tanstack/react-query";
import { enqueueSnackbar } from "notistack";
import type { IUser } from "../../entities/user.entity";
import {
	firebaseAddFirestore,
	firebaseDeleteFirestore,
} from "../../utils/firebase";
import { useUsersList } from "../hook/useUsersList";
import { captureException } from "@sentry/react";

export const useUserChanger = (params: { onSuccess?: () => void }) => {
	const usersList = useUsersList();
	return useMutation({
		mutationKey: ["users", "change"],
		mutationFn: async ({
			user,
			previousId,
		}: { user: IUser; previousId?: string }) => {
			if (previousId) {
				await firebaseDeleteFirestore(previousId, "users", []);
			}

			console.log("user", user);
			await firebaseAddFirestore("users", {
				...user,
			});
		},
		onError: (error) => {
			captureException(error);
			enqueueSnackbar({
				variant: "error",
				message: "Erro ao alterar usuário, tente novamente",
			});
		},
		onSuccess: () => {
			usersList.refetch();
			enqueueSnackbar({
				variant: "success",
				message: "Usuário alterado com sucesso",
			});
			params.onSuccess?.();
		},
	});
};
