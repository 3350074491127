import {
	Outlet,
	type RouteObject,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import { PageContainter, PageWithBackground } from "./SubPageLayout";
import { PlayerAuth } from "./PlayerAuth";
import { PlayerAuthRoute } from "./PlayerAuthRoute";
import { VideoAndTimeoutWrapper } from "./components/VideoAndTimeoutWrapper";
import { BigButtonsPage } from "./components/BigButtonsPage";
import { TvMuralButton } from "./components/TvMuralButton";
import { BigCategoriesList } from "./components/BigCategoriesList";
import { BreadCrumb } from "./components/BreadCrumb";
import {
	CategoryVideos,
	FilterableListController,
} from "./components/VideosList";
import { Header } from "./components/Header";
import EmbedPage from "./components/EmbedPage";

import zontaNewsBg from "../assets/buttons/tv_zonta_zontanews.svg";
import universidadeCorporativaBg from "../assets/buttons/tv_zonta_uc.svg";
import videoConferencia from "../assets/buttons/videoconferencia.svg";
import aovivoBg from "../assets/buttons/tv_zonta_aovivo.svg";
import institutoJoanirZontaBg from "../assets/buttons/tv_zonta_instituto.svg";
import Logo from "./components/Logo";
import transmissaoAoVivo from "../assets/buttons/tv_zonta_aovivo.svg";
import { ProvideReactQuery } from "./config/reactQuery";
import { UcOnlineButton } from "./components/UcOnlineButton";
import { MotionMain } from "./MotionMain";
import { CategoryRender } from "./CategoryRender";
import { usePlayerData } from "./usePlayerData";
import { AnalyticsProvider } from "./hooks/useAnalytics";
import { MegaMidiaTiagoAnalytics } from "./analytics/megamidiaTiago";
import { BigButtons } from "./components/BigButtons";
import { ContentContainer } from "./components/ContentContainer";
import { useQuery } from "@tanstack/react-query";
import axios, { type AxiosInstance } from "axios";
import { firebaseApp } from "../utils/firebase";
import { IconLoader, IconNetwork, IconNetworkOff } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { captureException } from "@sentry/react";

declare global {
	interface Window {
		api?: {
			openExternalLink: (url: string) => void;
			isInstalledPlayer: boolean;
			onRequestPassword: (callback: () => void) => void;
			passwordAccepted: () => void;
			requestExit: () => void;
		};
		getVideoFromDb: (url: string) => Promise<any>;
		loginWithEmailAndPassword: (
			email: string,
			password: string,
		) => Promise<any>;
	}
}

export const GroupVideos = ({
	groupName,
	title,
	ignoreCategories,
}: { groupName: string; title: string; ignoreCategories?: boolean }) => {
	const params = useParams();
	const playerData = usePlayerData();

	if (playerData.query.isFetching) {
		return (
			<div className="h-screen flex items-center justify-center">
				<div className="text-white">Carregando...</div>
			</div>
		);
	}

	if (playerData.query.isError || !playerData.query.data) {
		return (
			<div className="h-screen flex items-center justify-center">
				<div className="text-white">Erro ao carregar dados</div>
			</div>
		);
	}

	const group = playerData.query.data?.groups.find(
		(group) => group.name === groupName,
	);

	if (!group) {
		return (
			<div className="h-screen flex items-center justify-center">
				<div className="text-white">Grupo não encontrado</div>
			</div>
		);
	}

	if (ignoreCategories) {
		return (
			<FilterableListController breadcrumb={[title]} groupName={groupName} />
		);
	}

	const category = group.categories.find(
		(category) => category.id === params.category,
	);

	if (!category) {
		return (
			<div className="h-screen flex items-center justify-center">
				<div className="text-white">Categoria não encontrada</div>
			</div>
		);
	}

	const breadcrumb = [title, category.name];

	return (
		<FilterableListController breadcrumb={breadcrumb} groupName={groupName}>
			<div className="pb-14">
				<CategoryVideos
					groupName={groupName}
					categoryId={params.category!}
					breadcrumb={breadcrumb}
				/>
			</div>
		</FilterableListController>
	);
};

export const PlayerData = ({ children }: { children: React.ReactNode }) => {
	const playerData = usePlayerData();

	return (
		<div data-player={playerData.password} className="h-screen">
			{children}
		</div>
	);
};

export const AoVivoPage = () => {
	const navigate = useNavigate();

	return (
		<MotionMain key="/aovivo/index" className="flex flex-col h-full">
			<ContentContainer>
				<BreadCrumb
					H2Props={{
						className: "text-3xl pl-12 pb-6 md:pt-0 pt-6",
					}}
					steps={["Ao Vivo"]}
				/>
				<BigButtons
					buttons={[
						{
							label: "Video Conferência",
							backgroundUrl: videoConferencia,
							href: "https://app.zoom.us/wc/meetings?webp=1",
							target: "_blank",
						},
						{
							label: "Transmissão ao vivo",
							backgroundUrl: transmissaoAoVivo,
							onClick: () => {
								navigate("transmissaoaovivo");
							},
						},
					]}
				/>
			</ContentContainer>
		</MotionMain>
	);
};

const analytics = new MegaMidiaTiagoAnalytics();

interface CheckStrategy {
	check(): Promise<{
		status: "ok" | "error";
		message?: string;
		resourceName?: string;
	}>;
}

export class FirebaseDisconnectCheck implements CheckStrategy {
	http: AxiosInstance;
	constructor() {
		this.http = axios.create({
			baseURL: firebaseApp.options.databaseURL,
		});
	}

	async check() {
		try {
			await this.http.options("/?no-cache");
			console.log("Firebase is connected");
			return {
				status: "ok" as const,
				resourceName: "Internet",
				message: "Conectado",
			};
		} catch (error) {
			captureException(error);
			console.error("Firebase is disconnected", error);
			return {
				status: "error" as const,
				message: "Sem conexão com a internet",
				resourceName: "Internet",
			};
		}
	}
}

export class MegamidiaLoggingCheck implements CheckStrategy {
	http: AxiosInstance;

	constructor() {
		this.http = axios.create({
			baseURL: "https://api.tvtreinamento.com.br",
		});
	}

	async check() {
		try {
			await this.http.get("/");
			console.log("Firebase is connected");
			return {
				status: "ok" as const,
				resourceName: "Internet",
				message: "Conectado",
			};
		} catch (error) {
			captureException(error);
			console.error("Firebase is disconnected", error);
			return {
				status: "error" as const,
				message: "Sem conexão com a internet",
				resourceName: "Internet",
			};
		}
	}
}

const getIcon = (status: "ok" | "error" | "warn", loading: boolean) => {
	if (loading) {
		return IconLoader;
	}

	if (status === "ok") {
		return IconNetwork;
	}

	if (status === "error") {
		return IconNetworkOff;
	}

	return null;
};

export const StatusBar = ({
	status,
	message,
	loading = false,
}: { status: "ok" | "error" | "warn"; message: string; loading?: boolean }) => {
	const color =
		status === "ok" ? "green" : status === "error" ? "red" : "yellow";

	const Icon = getIcon(status, loading);

	return (
		<div
			className={`fixed bottom-10 right-0 bg-${color}-500 text-white p-2 z-50 flex items-center space-x-2 left-0 justify-center`}
		>
			{Icon && (
				<div>
					<Icon size={30} />
				</div>
			)}
			<div>{message}</div>
		</div>
	);
};

export const PlayerStatus = ({
	check,
	children,
}: { check: CheckStrategy; children: React.ReactNode }) => {
	const [visible, setVisible] = useState(false);

	const checkQuery = useQuery({
		queryKey: ["playerStatus"],
		queryFn: () => check.check(),
		cacheTime: 0,
		enabled: true,
		networkMode: "always",
		refetchInterval: 1000,
		staleTime: 0,
	});

	useEffect(() => {
		setVisible(true);
		const timeout = setTimeout(() => {
			if (checkQuery.data?.status === "ok") {
				setVisible(false);
			}
		}, 5000);

		return () => {
			setVisible(false);
			clearTimeout(timeout);
		};
	}, [checkQuery.data?.status]);

	let statusBar: React.ReactNode = null;

	if (!checkQuery.data && checkQuery.isLoading) {
		statusBar = (
			<StatusBar
				status="warn"
				message={"Verificando conexão com a internet"}
				loading
			/>
		);
	}

	if (checkQuery.data?.status === "error") {
		statusBar = (
			<StatusBar
				status="error"
				message={checkQuery.data.message || "Instabilidade"}
			/>
		);
	}

	if (checkQuery.data?.status === "ok") {
		statusBar = (
			<StatusBar status="ok" message={checkQuery.data.message || "Ok"} />
		);
	}

	return (
		<>
			{children}
			{visible && statusBar}
		</>
	);
};

const playerStatusCheck = new FirebaseDisconnectCheck();

const UNIVERSIDADE_CORPORATIVA_CATEGORIA_ID = "UCpv1l8ibs40GfO6A6s2";
export const routes: RouteObject = {
	path: "/",
	element: (
		<ProvideReactQuery>
			<PlayerStatus check={playerStatusCheck}>
				<AnalyticsProvider analytics={analytics}>
					<PageWithBackground>
						<Outlet />
					</PageWithBackground>
				</AnalyticsProvider>
			</PlayerStatus>
		</ProvideReactQuery>
	),
	children: [
		{
			path: "",
			element: <PlayerAuth />,
		},
		{
			path: "player",
			Component: () => {
				const location = useLocation();
				const disableHeader = /\/player$/.test(location.pathname);
				const headerIsVisible = !disableHeader;
				return (
					<VideoAndTimeoutWrapper>
						<PlayerAuthRoute>
							<PlayerData>
								{headerIsVisible && <Header />}
								<PageContainter disabled={disableHeader}>
									<Outlet />
								</PageContainter>
							</PlayerData>
						</PlayerAuthRoute>
					</VideoAndTimeoutWrapper>
				);
			},
			children: [
				{
					path: "",
					element: (
						<MotionMain key="/" className="flex flex-col md:h-full">
							<BigButtonsPage
								prefix={<Logo className="mt-8 md:mt-0 w-[30%]" />}
								buttons={[
									{
										label: "Instituto Joanir Zonta",
										backgroundUrl: institutoJoanirZontaBg,
										path: "instituto_joanir_zonta",
									},
									{
										label: "Zonta News",
										backgroundUrl: zontaNewsBg,
										path: "condor_news",
									},
									{
										label: "Universidade Corporativa",
										backgroundUrl: universidadeCorporativaBg,
										path: "treinamento",
									},
									{
										label: "Ao vivo",
										backgroundUrl: aovivoBg,
										path: "aovivo",
									},
								]}
								suffix={<TvMuralButton />}
							/>
						</MotionMain>
					),
				},
				{
					path: "condor_news",
					element: (
						<MotionMain key="/condor_news" className="flex flex-col h-full">
							<Outlet />
						</MotionMain>
					),
					children: [
						{
							path: "",
							element: (
								<MotionMain
									key="/condor_news/index"
									className="flex flex-col h-full"
								>
									<BigCategoriesList
										groupName="condor_news"
										name="Zonta News"
									/>
								</MotionMain>
							),
						},
						{
							path: ":category",
							element: (
								<MotionMain key="/condor_news/category">
									<GroupVideos groupName="condor_news" title="Zonta News" />
								</MotionMain>
							),
						},
						{
							path: ":category/:subcategory",
							element: (
								<MotionMain key="/condor_news/subcategory">
									<GroupVideos groupName="condor_news" title="Zonta News" />
								</MotionMain>
							),
						},
					],
				},
				{
					path: "instituto_joanir_zonta",
					element: (
						<MotionMain
							key="/instituto_joanir_zonta"
							className="flex flex-col h-full"
						>
							<Outlet />
						</MotionMain>
					),
					children: [
						{
							path: "",
							element: (
								<MotionMain
									key="/instituto_joanir_zonta/index"
									className="flex flex-col h-full"
								>
									<GroupVideos
										groupName="instituto_joanir_zonta"
										title="Instituto Joanir Zonta"
										ignoreCategories
									/>
								</MotionMain>
							),
						},
					],
				},
				{
					path: "aovivo",
					element: (
						<MotionMain key="/aovivo" className="flex flex-col h-full">
							<Outlet />
						</MotionMain>
					),
					children: [
						{
							path: "",
							element: <AoVivoPage />,
						},
						{
							path: "transmissaoaovivo",
							element: <EmbedPage name="Live" />,
						},
					],
				},
				{
					path: "treinamento",
					element: (
						<MotionMain key="/treinamento" className="flex flex-col h-full">
							<Outlet />
						</MotionMain>
					),
					children: [
						{
							path: "",
							element: (
								<CategoryRender
									groupName="treinamento"
									categoryId={UNIVERSIDADE_CORPORATIVA_CATEGORIA_ID}
									suffixButton={<UcOnlineButton />}
									name="Universidade Corporativa"
								/>
							),
						},
						{
							path: "uconline",
							element: <EmbedPage name="UC Online" />,
						},
						{
							path: ":subcategory",
							element: (
								<CategoryRender
									groupName="treinamento"
									categoryId={UNIVERSIDADE_CORPORATIVA_CATEGORIA_ID}
									name="Universidade Corporativa"
								/>
							),
						},
					],
				},
			],
		},
	],
};
