import { type FormEvent, useCallback, useState } from "react";
import Sidepane from "../components/Sidepane";
import VideoDetails from "../components/VideoDetails";
import VideosTable from "../components/VideosTable";
import type { IVideo } from "../../entities/video.entity";
import { useSidePane } from "../hook/useSidePane";
import { useVideoManager } from "../hook/useVideoManager";
import TableSearch from "../components/TableSearch";
import type { KeyboardEvent } from "react";
import { CrudContainer } from "../components/CrudContainer";

export default function Videos() {
	const [videoDetail, setVideoDetail] = useState<IVideo | undefined>(undefined);
	const [searchVideo, setSearchVideo] = useState("");

	const sidepane = useSidePane({
		onClose: () => setVideoDetail(undefined),
	});

	const videoManager = useVideoManager({
		onCreated: () => sidepane.setOpen(false),
		filter: searchVideo,
	});

	const handleChangeSearch = useCallback(
		(e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			setSearchVideo(e.target.value);
		},
		[],
	);

	const handlePageChange = useCallback(
		(_, value: number) => {
			videoManager.setPage(value);
		},
		[videoManager],
	);

	const handleRowsPerPageChange = useCallback((event) => {
		videoManager.setRowsPerPage(event.target.value);
	}, []);

	function handleUpdateVideo(id: string) {
		const data = videoManager.getById(id);

		if (!data) {
			throw new Error("Video not found");
		}

		setVideoDetail(data);
		sidepane.setOpen(true);
	}

	const handleSubmitVideo = async (
		event: FormEvent<HTMLFormElement>,
		data: IVideo,
	) => {
		event.preventDefault();
		videoManager.videoSubmit.mutate(data);
	};

	return (
		<>
			<CrudContainer
				title="Vídeos"
				onClickAdd={() => sidepane.setOpen(true)}
				subtitle="Gerencie seus vídeos"
				empty={
					videoManager.videosQuery.isFetched &&
					videoManager.videosQuery.data?.length === 0
				}
			>
				<div className="p-4">
					<TableSearch handleChangeSearch={handleChangeSearch} />
				</div>
				{videoManager.videosQuery.isFetched && (
					<VideosTable
						count={videoManager.count}
						items={videoManager.videoPaginated}
						onPageChange={handlePageChange}
						onRowsPerPageChange={handleRowsPerPageChange}
						videoRemover={videoManager.videoRemover}
						onUpdateItem={handleUpdateVideo}
						page={videoManager.page}
						rowsPerPage={videoManager.rowsPerPage}
					/>
				)}
			</CrudContainer>

			<Sidepane open={sidepane.isOpen} toggle={sidepane.toggle}>
				<VideoDetails
					isSaving={videoManager.videoSubmit.isLoading}
					item={videoDetail}
					handleSubmitVideo={handleSubmitVideo}
				/>
			</Sidepane>
		</>
	);
}
