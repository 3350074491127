import { playerActivationStore } from "../../utils/firebase";
import { useAnalytics } from "../hooks/useAnalytics";
import { BigButton } from "./BigButton";
import { openUrl } from "./openUrl";

export type BigButtonsProps = {
	buttons: Array<{
		label: string;
		backgroundUrl?: string;
		content?: React.ReactNode;
		onClick?: () => void;
		href?: string;
		target?: string;
		button?: React.ReactNode;
	}>;
	suffix?: React.ReactNode;
};
export function BigButtons(props: BigButtonsProps) {
	const analytics = useAnalytics();
	return (
		<div className="flex flex-wrap w-full justify-center">
			{props.buttons.map((button) => {
				if (button.button) {
					return button.button;
				}

				return (
					<BigButton
						key={button.label}
						{...button}
						onClick={() => {
							analytics.click(
								playerActivationStore.activationPassword!,
								button.label,
							);
							if (button.href) {
								openUrl(button.href);
								return;
							}
							if (button.onClick) button.onClick();
						}}
					/>
				);
			})}
			{props.suffix}
		</div>
	);
}
