/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from "react-router-dom";
import { BigButtons } from "./BigButtons";
import { CircularProgress } from "@mui/material";
import { ErrorMessage, FilterableListController } from "./VideosList";
import { usePlayerData } from "../usePlayerData";

export const BigCategoriesList = (props: {
	name: string;
	groupName: string;
	suffix?: React.ReactNode;
}) => {
	const navigate = useNavigate();
	const playerData = usePlayerData();

	if (playerData.query.isFetching) {
		return <CircularProgress />;
	}

	if (playerData.query.isError) {
		return <div>Erro ao carregar dados do player</div>;
	}

	const currentGroup = playerData.query.data?.groups.find(
		(group) => group.name === props.groupName,
	);

	if (!currentGroup) {
		return (
			<ErrorMessage message={`Grupo não encontrado: ${props.groupName}`} />
		);
	}

	return (
		<FilterableListController
			groupName={props.groupName}
			breadcrumb={[props.name]}
		>
			{/* <code className="text-black">
				<pre>{JSON.stringify(currentGroup, null, 2)}</pre>
			</code> */}
			<BigButtons
				suffix={props.suffix}
				buttons={currentGroup.categories.map((channel) => {
					return {
						label: channel.name,
						backgroundUrl: channel.bgImage.url,
						onClick() {
							navigate(`${channel.id}`);
						},
					};
				})}
			/>
		</FilterableListController>
	);
};
